import React, { memo } from "react";

const CurrencyDropdown = ({
  network,
  setSelect,
  selectedNetwork,
  setSelectedNetwork,
}) => {
  const handleSelectNetwork = (net) => {
    setSelectedNetwork(net);
    setSelect(false);
  };

  return (
    <>
      <div className="w-full  border mt-2 rounded-xl flex flex-col gap-2 p-2 max-h-44  overflow-y-auto">
        {network.map((curr, id) => (
          <div
            onClick={() => handleSelectNetwork(curr)}
            key={id}
            className="p-1 flex items-center gap-2 cursor-pointer hover:bg-bg-color/50 rounded-lg"
          >
            <img
              src={curr.logo}
              width={20}
              height={20}
              alt={curr.name}
              loading="lazy"
              className="rounded-full"
            />
            <h5 className="text-sm">{curr.name}</h5>
          </div>
        ))}
      </div>
    </>
  );
};

export default memo(CurrencyDropdown);
