import React, { useState } from "react";
import MainLayout from "../../components/Layouts/MainLayout";
import { CircleChevronLeft, Search, TicketSlash } from "lucide-react";
import { Link } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import ManageOfferBenefitTable from "../../components/Tables/ManageOfferBenefitTable";
import Pagination from "../../components/Pagination";
import AddOfferBenefitModal from "../../components/Modals/AddOfferBenefitModal";
import DeleteOfferBenefitModal from "../../components/Modals/DeleteOfferBenefitModal";
import { useQuery } from "react-query";
import { getUser } from "../../services/auth";
import { fetchOfferBenefits } from "../../services/manageOfferServices";

const ManageOfferBenefits = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [offerId, setOfferId] = useState(null);

  const limit = 10;

  const { modal, setModal } = useAppContext();

  // For Change the currect page in pagination
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const {
    data: offerBenefits,
    isLoading,
    refetch: triggerRefetch,
  } = useQuery({
    queryKey: ["offerBenefits", currentPage, limit],
    queryFn: async () => {
      const user = await getUser();
      const response = await fetchOfferBenefits(user, currentPage, limit);
      return response.data;
    },
    onSuccess: (data) => {
      if (data) {
        setTotalPages(data.totalPages);
      } else {
        console.warn("Total pages not found in response:", data);
      }
    },
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000,
  });

  return (
    <>
      {modal === "add-benefits" && (
        <AddOfferBenefitModal triggerRefetch={triggerRefetch} />
      )}
      {modal === "delete" && (
        <DeleteOfferBenefitModal
          triggerRefetch={triggerRefetch}
          offerId={offerId}
        />
      )}
      <MainLayout>
        <div className="w-full h-full">
          <div className="pb-4">
            <Link
              to="/dashboard"
              className="flex  items-center gap-2 bg-custom-border/20 hover:bg-custom-border/30 p-2 w-fit rounded-xl"
            >
              <CircleChevronLeft className="w-4 h-4" />
              <h1 className="font-semibold text-sm">Manage Offer Benefits</h1>
            </Link>
          </div>

          <div>
            <div className="flex flex-col sm:flex-row gap-4 justify-between w-full h-full">
              <div className=" w-full flex items-center p-2 gap-2 max-w-xl border rounded-full h-10">
                <Search className="search" />
                <input
                  type="search"
                  id="search"
                  placeholder="Search Name/Mobile No./ Email ID"
                  className="w-full outline-none"
                />
              </div>
              <div className="">
                <button
                  onClick={() => setModal("add-benefits")}
                  className="w-full h-full p-2 px-4 bg-custom-border hover:bg-opacity-85 text-white rounded-xl text-sm"
                >
                  Add Offer Benefits
                </button>
              </div>
            </div>

            <div className="mt-4 w-full h-full p-4 border rounded-xl shadow-lg overflow-hidden">
              {offerBenefits?.docs?.length > 0 ? (
                <div>
                  <ManageOfferBenefitTable
                    setModal={setModal}
                    offerBenefits={offerBenefits}
                    currentPage={currentPage}
                    limit={limit}
                    setOfferId={setOfferId}
                    triggerRefetch={triggerRefetch}
                  />
                </div>
              ) : (
                <div className="w-full h-96 flex flex-col gap-1.5 justify-center items-center">
                  <span className="flex justify-center items-center w-10 h-10 bg-yellow-200 rounded-lg">
                    <TicketSlash className="w-6 h-6 text-orange-500" />
                  </span>
                  <p>There are no offer benefits available.</p>
                </div>
              )}
              {totalPages > 1 && (
                <div className="mt-4">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default ManageOfferBenefits;
