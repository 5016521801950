import { httpAxios } from "../helper/httpHelper";

export async function restoredIdHistory(user, page, limit) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/user/deleted/history?page=${page}&limit=${limit}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: user,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response?.data || { message: "An error occurred" };
  }
}

export async function getDeletedIds(user, searchQuery) {
  try {
    // Construct the base URL with required params
    let url = `/retailer/user/deleted/list?search=${searchQuery}`;

    // Append the search query param only if it exists
    // if (searchQuery) {
    //   url += `&search=${searchQuery}`;
    // }

    const { data } = await httpAxios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: user,
      },
    });

    return data;
  } catch (error) {
    return error.response?.data || { message: "An error occurred" };
  }
}

export async function checkout(user) {
  try {
    const { data } = await httpAxios.post(
      `/retailer/user/deleted/restore/checkout`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: user,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response?.data || { message: "An error occurred" };
  }
}

export async function retore(user, checkoutId, userId) {
  try {
    // console.log("Params", checkoutId, userId);
    
    const { data } = await httpAxios.post(
      `/retailer/user/deleted/restore`,
      { checkoutId, userId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: user,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response?.data || { message: "An error occurred" };
  }
}
