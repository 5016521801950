import React, { useState } from "react";
import MainLayout from "../../components/Layouts/MainLayout";
import {
  CircleChevronLeft,
  FileBarChart2,
  GitPullRequest,
  RefreshCcw,
  Search,
} from "lucide-react";
import PendingStateRequestTable from "../../components/Tables/State/PendingStateRequestTable";
import Pagination from "../../components/Pagination";
import { useQuery } from "react-query";
import { getUser } from "../../services/auth";
import { fetchPendingStateRequests } from "../../services/state/retailerServices";
import { useAppContext } from "../../context/AppContext";
import AcceptStateModal from "../../components/Modals/State/AcceptStateModal";
import RejectStateModal from "../../components/Modals/State/RejectStateModal";
import { Link } from "react-router-dom";

const PendingStateRequestScreen = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [query, setQuery] = useState("");
  const [selected, setSelected] = useState({});

  const { userData, modal, setModal } = useAppContext();

  const limit = 10;

  const handleSearchChange = (e) => {
    setQuery(e.target.value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const {
    data: pendingStateRequests,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["pendingStateRequests", currentPage, limit, query],
    queryFn: async () => {
      const user = await getUser();
      const response = await fetchPendingStateRequests(
        user,
        currentPage,
        limit,
        query
      );
      return response.data;
    },
    onSuccess: (data) => {
      if (data) {
        // console.log("data", data.docs);
        setTotalPages(data.totalPages);
      }
    },
    enabled: userData.role === "STATE",
    refetchInterval: 300000,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const handleAction = (action, user) => {
    setModal(action);
    setSelected(user);
  };

  return (
    <>
      {modal === "accept" && (
        <AcceptStateModal selected={selected} refetch={refetch} />
      )}
      {modal === "reject" && (
        <RejectStateModal selected={selected} refetch={refetch} />
      )}
      <MainLayout>
        <div className="sm:w-fit h-full flex justify-center items-center">
          <Link
            to="/dashboard"
            className="font-suse flex gap-2 w-full h-11 items-center whitespace-nowrap rounded-xl bg-gray-100 hover:bg-gray-200 p-2"
          >
            <CircleChevronLeft className="w-5 h-5" />
            Pending Requests
          </Link>
        </div>
        {userData.role === "STATE" && (
          <div className="mt-8">
            <div className="flex flex-col sm:flex-row gap-6 justify-between sm:items-center">
              <div className="flex items-center gap-2 h-10">
                <FileBarChart2 className="w-10 h-10 text-bg-color" />
                <span>
                  <h1 className="font-semibold text-lg sm:text-xl">
                    Pending Requests
                  </h1>
                  <p className="text-sm sm:text-base">
                    Pending state requests send by retailers.
                  </p>
                </span>
              </div>
              <div className="border border-bg-color flex items-center gap-2 p-2 rounded-xl sm:max-w-64 w-full">
                <Search className="w-5 h-5 text-bg-color" />
                <input
                  value={query}
                  onChange={handleSearchChange}
                  type="search"
                  placeholder="Search"
                  className="w-full bg-transparent outline-none"
                />
              </div>
            </div>

            <div className="mt-8">
              {isLoading ? (
                <div className="min-h-96 w-full flex flex-col gap-2 justify-center items-center rounded-xl shadow-xl">
                  <RefreshCcw className="w-6 h-6 animate-spin" />
                  <p>Loading pending requests...</p>
                </div>
              ) : pendingStateRequests?.docs?.length > 0 ? (
                <PendingStateRequestTable
                  data={pendingStateRequests}
                  currentPage={currentPage}
                  limit={limit}
                  handleAction={handleAction}
                />
              ) : (
                <div className="min-h-96 w-full flex gap-2 flex-col justify-center items-center rounded-xl shadow-lg border ">
                  <GitPullRequest className="w-6 h-6" />
                  <p>There are no pending requests.</p>
                </div>
              )}
            </div>

            {totalPages > 1 && (
              <div className="mt-6">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            )}
          </div>
        )}
      </MainLayout>
    </>
  );
};

export default PendingStateRequestScreen;
