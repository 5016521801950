import React from "react";
import { restoreArr } from "../../array/restoreArr";
import { RotateCcw } from "lucide-react";
import { formatDate, formatDateMonthYear } from "../../utils/formatTime";

const BurnRestoreTable = ({
  selectedUser,
  setSelectedUser,
  restoredIds,
  currentPage,
  limit,
}) => {
  // console.log(restoredIds?.docs);

  // console.log("selectedUser", selectedUser);

  return (
    <>
      <div className="overflow-x-auto overflow-y-hidden">
        <div className="align-middle inline-block min-w-full">
          <div className="overflow-hidden border border-custom-border">
            <table className="min-w-full">
              <thead className="bg-sub-card border-b border-custom-border bg-gradient-to-tr from-custom-border to-bg-color text-white">
                <tr>
                  <th
                    scope=""
                    className="px-4 py-4 text-left text-xs font-semibold uppercase tracking-wider "
                  >
                    Serial
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 text-left text-xs font-semibold uppercase tracking-wider whitespace-nowrap"
                  >
                    Member Name
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 text-left text-xs font-semibold uppercase tracking-wider whitespace-nowrap"
                  >
                    Member ID
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 text-left text-xs font-semibold uppercase tracking-wider whitespace-nowrap"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 text-left text-xs font-semibold uppercase tracking-wider whitespace-nowrap"
                  >
                    Restored Date/Time
                  </th>
                  {/* <th
                    scope="col"
                    className="px-4 py-4 text-left text-xs font-semibold uppercase tracking-wider whitespace-nowrap"
                  >
                    Action
                  </th> */}
                </tr>
              </thead>
              <tbody className="">
                {restoredIds?.docs?.map((acc, id) => (
                  <tr
                    key={id}
                    className={`${
                      id % 2 === 0 ? "bg-white" : "bg-bg-color/10"
                    }`}
                  >
                    <td className="px-4 py-4 whitespace-nowrap text-sm">
                      {id + 1 + (currentPage - 1) * limit}.
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm">
                      {acc?.fromUser?.name}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm">
                      {acc?.fromUser?.referralCode}
                    </td>
                    <td
                      className={`px-4 py-4 whitespace-nowrap text-sm italic font-semibold`}
                    >
                      {acc?.isPermanentlyDeleted ? (
                        <p className="text-red-500">Deleted</p>
                      ) : (
                        <p className="text-emerald-500">Restored</p>
                      )}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm">
                      {formatDate(acc?.updatedAt)}
                    </td>
                    {/* <td className="px-4 py-4 whitespace-nowrap text-sm ">
                      {acc?.isPermanentlyDeleted ? (
                        <button
                          onClick={() => setSelectedUser(acc)}
                          className=" p-2 px-4 h-8 flex items-center gap-1.5 bg-orange-700 hover:bg-opacity-85 text-white rounded-lg text-sm"
                        >
                          <RotateCcw className="w-4 h-4" />
                          Retore
                        </button>
                      ) : (
                        <button
                          onClick={() => setSelectedUser(acc)}
                          className=" p-2 px-4 h-8 bg-custom-border hover:bg-opacity-85 text-white rounded-lg text-xs"
                        >
                          View Profile
                        </button>
                      )}
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default BurnRestoreTable;
