import { httpAxios } from "../helper/httpHelper";

export async function createMember(user, formData) {
  try {
    // const params = {
    //   name: formData.name,
    //   email: formData.email,
    //   mobile: formData.mobile,
    //   password: formData.password,
    //   balance: Number(formData.balance),
    // };

    const { data } = await httpAxios.post(
      `/retailer/subretailer/create`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: user,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function subRetailerMembersList(user, currPage, limit, search) {
  try {
    const searchQuery = search ? `&search=${search}` : "";

    const { data } = await httpAxios.get(
      `/retailer/subretailer/list?page=${currPage}&limit=${limit}${searchQuery}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: user,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function addAmount(user, userId, amount) {
  try {
    const params = {
      userId: userId,
      amount: amount,
    };
    const { data } = await httpAxios.post(
      `/retailer/subretailer/balance/add`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: user,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function deductAmount(user, userId, amount) {
  try {
    const params = {
      userId: userId,
      amount: amount,
    };

    const { data } = await httpAxios.post(
      `/retailer/subretailer/balance/deduct`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: user,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function deleteMember(user, userId) {
  try {
    const { data } = await httpAxios.delete(
      `/retailer/subretailer/delete?id=${userId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: user,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function deleteStateMember(user, userId) {
  try {
    const { data } = await httpAxios.delete(
      `/retailer/state-user/delete?id=${userId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: user,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function memberTxnsList(user, userId, currPage, limit) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/subretailer/balance/transaction/list?userId=${userId}&page=${currPage}&limit=${limit}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: user,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function stateMemberTxnsList(user, userId, currPage, limit) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/state-user/balance/transaction/list?userId=${userId}&page=${currPage}&limit=${limit}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: user,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function editSubRetailer(user, userId, formData) {
  try {
    const params = {
      id: userId,
      ...formData,
    };

    const { data } = await httpAxios.put(
      `/retailer/subretailer/update`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: user,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function editStateMembers(user, userId, formData) {
  try {
    const params = {
      id: userId,
      ...formData,
    };

    const { data } = await httpAxios.put(
      `/retailer/state-user/update`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: user,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}
