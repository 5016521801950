import React, { memo, useCallback, useState } from "react";
import ModalLayout from "../../Layouts/ModalLayout";
import "../modal.css";
import { X } from "lucide-react";
import RedirectAppModal from "./RedirectAppModal";
import { getUser } from "../../../services/auth";
import { recharge } from "../../../services/dashboardServices";
import { toast } from "react-toastify";

const AddFundModal = ({
  setModal,
  selectedLink,
  setReceiptRefresh,
  setRechargeDetails,
}) => {
  const [loading, setLoading] = useState(false);

  const handleRecharge = async () => {
    try {
      if (selectedLink._id) {
        const user = await getUser();
        const data = await recharge(user, selectedLink?._id);
        // console.log("Recharge", data);
        if (data.status) {
          setReceiptRefresh(true);
          setModal("submit-2");
          setRechargeDetails(data.data);
        } else {
          toast.error(data.message);
        }
      } else {
        toast.error("User not found");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePayment = useCallback(
    (paymentLink) => {
      setLoading(true);
      try {
        if (paymentLink) {
          handleRecharge();
          setLoading(false);
          window.open(paymentLink, "_blank");
          setModal("payapp");
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
    [setModal, setLoading]
  );

  return (
    <>
      {loading && <RedirectAppModal />}
      <ModalLayout maxWidth="max-w-96">
        <div className="bg-white w-full h-full ">
          <div className="modal-header">
            <div className="flex-1 flex"></div>
            <div className="modal-header-body2">Add Fund</div>
            <div onClick={() => setModal("")} className="modal-header-body">
              <X style={{ cursor: "pointer" }} />
            </div>
          </div>
          <div className="p-4">
            <div className="flex flex-col justify-center items-center py-16">
              <h2 className="text-xl font-semibold">{selectedLink.amount}</h2>
              <p className="text-xl font-semibold">Amount</p>
            </div>
            <div className=" flex justify-center items-center">
              <button
                onClick={() => handlePayment(selectedLink.link)}
                type="button"
                className="bg-bg-color hover:bg-bg-color/80 w-full py-2 rounded-lg text-white"
              >
                Pay Now
              </button>
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default memo(AddFundModal);
