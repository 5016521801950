import React from "react";
import ModalLayout from "../../Layouts/ModalLayout";
import { X } from "lucide-react";

const DeductWarrningModal = ({ setModal }) => {
  return (
    <>
      <ModalLayout maxWidth="max-w-fit">
        <div className="confirmation-card font-suse">
          <div className="card-header">
            <h2>Confirmation</h2>
            <X className="close-icon" onClick={() => setModal("")} />
          </div>
          <p className="warr-p">Are you sure you want to Deduct Amount?</p>
          <div className="button-container">
            <button
              className="confirm-button"
              onClick={() => setModal("deduct-amount")}
            >
              Confirm
            </button>
            <button className="cancel-button" onClick={() => setModal("")}>
              Cancel
            </button>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default DeductWarrningModal;
