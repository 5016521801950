import React, { useState, useRef } from "react";
import { useAppContext } from "../context/AppContext";
import { ChevronDown } from "lucide-react";

const LinksDropdown = ({ options, onChange }) => {
  const { country } = useAppContext();
  const [modal, setModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);

  const handleOptionClick = (option) => {
    // console.log("option", option);
    setSelectedOption(option);
    onChange(option);
    setModal(false);
  };

  const handleDropdownClick = () => {
    setModal(!modal);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setModal(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="w-full h-full" ref={dropdownRef}>
      <div
        className="h-10 border border-custom-border p-1 rounded-lg  px-2 flex items-center cursor-pointer"
        onClick={handleDropdownClick}
      >
        {selectedOption ? (
          <>
            <img
              src={country?.currencySymbol}
              alt="symbol"
              width={12}
              height={12}
              style={{ marginRight: "8px" }}
            />
            {selectedOption.amount}
          </>
        ) : (
          <div className="flex justify-between w-full items-center text-sm">
            <p className="text-sm whitespace-nowrap overflow-hidden">Select an amount</p>
            <ChevronDown className="w-4 h-4" />
          </div>
        )}
      </div>
      {modal && (
        <div className="absolute bg-white border border-custom-border rounded-xl mt-1 w-64 z-50">
          {options.map((option) => (
            <div
              key={option._id}
              className="hover:bg-custom-border/20 p-2 flex justify-start items-center rounded-lg cursor-pointer"
              onClick={() => handleOptionClick(option)}
            >
              <img
                src={country?.currencySymbol}
                alt="symbol"
                width={12}
                height={12}
                style={{ marginRight: "8px" }}
              />
              {option.amount}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LinksDropdown;
