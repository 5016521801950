import React, { useState } from "react";
import MainLayout from "../../components/Layouts/MainLayout";
import { Link } from "react-router-dom";
import {
  CircleChevronLeft,
  GitPullRequest,
  RefreshCcw,
  Search,
} from "lucide-react";
import { useQuery } from "react-query";
import { getUser } from "../../services/auth";
import { fetchAcceptedReports } from "../../services/state/retailerServices";
import ReportTable from "../../components/Tables/State/ReportTable";
import Pagination from "../../components/Pagination";

const AcceptedStateReports = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const limit = 10;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const { data, isLoading } = useQuery({
    queryKey: ["acceptedReport", currentPage, limit, searchQuery],
    queryFn: async () => {
      const user = await getUser();
      const res = await fetchAcceptedReports(user, currentPage, limit, searchQuery);
      return res.data;
    },
    onSuccess: (data) => {
      if (data) {
        setTotalPages(data.totalPages || 1);
      }
    },
    onError: (error) => {
      console.error(error);
    },
  });

  return (
    <>
      <MainLayout>
        <div>
          <div className="sm:w-fit h-full flex justify-center items-center">
            <Link
              to="/dashboard"
              className="font-suse flex gap-2 w-full h-11 items-center whitespace-nowrap rounded-xl bg-gray-100 hover:bg-gray-200 p-2"
            >
              <CircleChevronLeft className="w-5 h-5" />
              Accepted Reports
            </Link>
          </div>

          <div className="mt-8">
            <div className="mb-6 max-w-xl">
              <div className="flex gap-2 border border-custom-border/30 p-1 rounded-xl w-full h-11">
                <span className="input-icon">
                  <Search style={{ width: "20px" }} />
                </span>
                <input
                  value={searchQuery}
                  onChange={handleSearchChange}
                  type="text"
                  title="search"
                  name="Search"
                  placeholder="Search"
                  className="w-full outline-none"
                />
              </div>
            </div>
            {isLoading ? (
              <div className="min-h-96 w-full flex flex-col gap-2 justify-center items-center">
                <RefreshCcw className="w-6 h-6 animate-spin" />
                <p>Loading pending requests...</p>
              </div>
            ) : data?.docs?.length > 0 ? (
              <ReportTable
                data={data}
                currentPage={currentPage}
                limit={limit}
              />
            ) : (
              <div className="min-h-96 w-full flex gap-2 flex-col justify-center items-center">
                <GitPullRequest className="w-6 h-6" />
                <p>Reports not found.</p>
              </div>
            )}
          </div>

          {totalPages > 1 && (
            <div className="mt-6">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </MainLayout>
    </>
  );
};

export default AcceptedStateReports;
