import React, { useState } from "react";
import ModalLayout from "../Layouts/ModalLayout";
import { ImageUp, Trash2, User, X } from "lucide-react";
import { useAppContext } from "../../context/AppContext";
import { toast } from "react-toastify";
import {
  updateProfilePicture,
  uploadMediaFile,
} from "../../services/userServices";
import { getUser } from "../../services/auth";
import { useMutation } from "react-query";

const ProfileModal = () => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [submitDetails, setSubmitDetails] = useState({});
  const [error, setError] = useState("");
  const { setModal, userData, setRefresh } = useAppContext();

  // For Upload the img in media API
  const uploadMutation = useMutation(
    async (file) => {
      const user = await getUser();
      return uploadMediaFile(user, file);
    },
    {
      onSuccess: (data) => {
        if (data.status) {
          toast.success(data.message);
          setSubmitDetails(data.data.media);
        } else {
          toast.error(data.message);
        }
      },
      onError: (error) => {
        toast.error(error.message || "Error uploading file");
      },
    }
  );

  // For input change or upload img from desktop
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const validTypes = ["image/png", "image/jpeg", "image/jpg"];
      const maxSize = 400 * 1024; // 400 KB in bytes

      if (!validTypes.includes(file.type)) {
        setError("Invalid file format. Only PNG, JPEG, and JPG are allowed.");
        setUploadedFile(null);
        return;
      }

      if (file.size > maxSize) {
        setError("File size must be under 400KB.");
        setUploadedFile(null);
        return;
      }

      setUploadedFile(file);
      uploadMutation.mutate(file);
      setError("");
    }
  };

  // For Delete File
  const handleDeleteFile = () => {
    setUploadedFile(null);
    setError("");
  };

  // For Update Profile Picture
  const submitMutation = useMutation(
    async () => {
      const user = await getUser();
      return updateProfilePicture(user, submitDetails);
    },
    {
      onSuccess: (data) => {
        if (data.status) {
          setRefresh(true);
          setModal("");
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
      },
      onError: (error) => {
        toast.error(error.message || "Error submitting receipt");
      },
    }
  );

  return (
    <ModalLayout maxWidth="max-w-md">
      <div>
        <div className="flex justify-between items-center p-4 border-b">
          <h1 className="text-lg font-semibold">Change Profile Picture</h1>
          <button
            onClick={() => setModal("")}
            className="hover:bg-gray-200 hover:text-red-600 p-1 rounded-full"
          >
            <X />
          </button>
        </div>

        <div className="flex flex-col justify-center items-center p-4">
          <div className="flex flex-col gap-4 w-full mt-4">
            <div className="relative w-full">
              <input
                type="file"
                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                id="fileInput"
                onChange={handleFileChange}
              />
              <div>
                {uploadedFile ? (
                  <div className="w-20 h-20 rounded-full p-1 border bg-gray-100">
                    <img
                      src={URL.createObjectURL(uploadedFile)}
                      alt="Upload Preview"
                      className="h-full w-full object-cover rounded-full"
                    />
                  </div>
                ) : (
                  <div className="w-20 h-20 rounded-full p-1 border bg-gray-100 flex justify-center items-center">
                    {userData?.kyc?.userSelfie ? (
                      <img
                        loading="lazy"
                        src={userData?.kyc?.userSelfie}
                        alt="Upload Preview"
                        className="h-full w-full object-cover rounded-full"
                      />
                    ) : (
                      <User className="w-10 h-10 text-gray-500" />
                    )}
                  </div>
                )}
              </div>
              <label
                htmlFor="fileInput"
                className="border flex w-full items-center gap-2 p-2 rounded-xl mt-4"
              >
                <ImageUp className="w-5 h-5" />
                <p className="text-xs">Upload your profile picture</p>
              </label>
              {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
              <div className="text-xs text-neutral-400 text-right flex justify-end items-center gap-2 relative mt-2">
                <p>Format: PNG, JPG, JPEG</p>
                {uploadedFile && (
                  <div className="relative bg-white h-10 rounded-lg flex justify-end items-center ">
                    <button
                      title="Delete"
                      onClick={handleDeleteFile}
                      className="p-1 bg-red-300 rounded-md hover:bg-opacity-85"
                    >
                      <Trash2 className="w-4 h-4 text-red-500" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="mt-12 flex items-center gap-4 w-full">
            <button
              disabled={uploadedFile === null}
              onClick={() => submitMutation.mutate()}
              className="w-full h-10 bg-bg-color text-white rounded-xl hover:opacity-85 disabled:cursor-not-allowed disabled:opacity-45"
            >
              Save
            </button>
            <button
              onClick={() => setModal("")}
              className="w-full h-10 border rounded-xl hover:bg-black/5"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default ProfileModal;
