import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  exportStateCommissionCSV,
  fetchStateCommission,
} from "../../../services/dashboardServices";
import { getUser } from "../../../services/auth";
import Pagination from "../../Pagination";
import {
  CloudDownload,
  LeafyGreenIcon,
  RefreshCcw,
  Search,
} from "lucide-react";
import { formatDate } from "../../../utils/formatTime";
import { toast } from "react-toastify";

const CommissionTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [status, setStatus] = useState("COMPLETED");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  const limit = 10;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleExportTxns = async () => {
    try {
      const user = await getUser();
      const csvData = await exportStateCommissionCSV(user);

      const blob = new Blob([csvData], { type: "text/csv" });
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.setAttribute("download", "StateCommissionList.csv");

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error(error);
      toast.error("Failed to export CSV.");
    }
  };

  const { data: stateCommissions, isLoading } = useQuery({
    queryKey: [
      "stateCommissions",
      currentPage,
      limit,
      status,
      searchQuery,
      selectedDate,
    ],
    queryFn: async () => {
      const user = await getUser();
      const res = await fetchStateCommission(
        user,
        currentPage,
        limit,
        status,
        selectedDate,
        searchQuery
      );
      return res.data;
    },
    onSuccess: (data) => {
      if (data) {
        setTotalPages(data.totalPages || 1);
      } else {
        console.error("Couldn't fetch stateCommission");
      }
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchInterval: 300000,
  });

  // console.log(stateCommissions);

  return (
    <>
      <div className="pb-4">
        <h1 className="sm:text-lg font-semibold text-center sm:text-left">
          Commissions Table
        </h1>
      </div>
      <div className="pb-4 flex flex-col sm:flex-row justify-between sm:items-center gap-2 w-full">
        <div className="border p-2 rounded-xl flex items-center gap-2 h-10">
          <Search className="w-5 h-5" />
          <input
            value={searchQuery}
            onChange={handleSearchChange}
            type="search"
            placeholder="Search..."
            className="w-full outline-none"
          />
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 justify-end items-end lg:max-w-[40rem] xl:max-w-[44rem] gap-2 text-sm">
          <div className="flex gap-1 border p-1 rounded-xl w-full xl:w-56 h-10">
            <input
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              type="date"
              title="search"
              name="Date"
              placeholder="Search"
              className="outline-none w-full"
            />
          </div>
          <div className="flex gap-1 border p-1 rounded-xl w-full xl:w-56 h-10">
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="w-full outline-none"
            >
              <option value="" disabled hidden>
                Status
              </option>
              <option value="COMPLETED">COMPLETED</option>
              <option value="PENDING">PENDING</option>
            </select>
          </div>
          <div className="flex gap-1 border p-1 rounded-xl w-full col-span-2 sm:col-span-1 h-10">
            <button
              disabled={stateCommissions?.docs?.length === 0}
              onClick={handleExportTxns}
              className="h-full flex items-center justify-center gap-1 bg-bg-color disabled:bg-bg-color/50 disabled:cursor-not-allowed w-full rounded-lg px-2 text-white"
            >
              <CloudDownload style={{ width: "16px" }} /> <span>|</span>CSV
            </button>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="min-h-96 w-full flex flex-col gap-2 justify-center items-center font-suse">
          <RefreshCcw className="w-6 h-6 animate-spin" />
          <p>Loading commissions...</p>
        </div>
      ) : stateCommissions?.docs?.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b whitespace-nowrap text-left">
                  No
                </th>
                <th className="py-2 px-4 border-b whitespace-nowrap text-left">
                  Txn No
                </th>
                <th className="py-2 px-4 border-b whitespace-nowrap text-left">
                  Retailer Member Id
                </th>
                <th className="py-2 px-4 border-b whitespace-nowrap text-left">
                  Retailer Name
                </th>
                <th className="py-2 px-4 border-b whitespace-nowrap text-left">
                  Sub Retailer Member Id
                </th>
                <th className="py-2 px-4 border-b whitespace-nowrap text-left">
                  Sub Retailer Name
                </th>
                <th className="py-2 px-4 border-b whitespace-nowrap text-left">
                  Service Type
                </th>
                <th className="py-2 px-4 border-b whitespace-nowrap text-left">
                  Pincode
                </th>
                <th className="py-2 px-4 border-b whitespace-nowrap text-left">
                  Order Date
                </th>
                <th className="py-2 px-4 border-b whitespace-nowrap text-left">
                  Status
                </th>
                <th className="py-2 px-4 border-b whitespace-nowrap text-left">
                  Earn
                </th>
              </tr>
            </thead>
            <tbody>
              {stateCommissions?.docs?.map((state, id) => (
                <tr
                  key={id}
                  className="text-sm"
                  // className={id % 2 === 0 ? "border" : "bg-bg-color/10"}
                >
                  <td className="p-4 border-b whitespace-nowrap">
                    {id + 1 + (currentPage - 1) * limit}.
                  </td>
                  <td className="p-4 border-b whitespace-nowrap">
                    {state?.transactionId ?? "-"}
                  </td>
                  <td className="p-4 border-b whitespace-nowrap">
                    {state?.retailer?.referralCode ?? "-"}
                  </td>
                  <td className="p-4 border-b whitespace-nowrap">
                    {state?.retailer?.name ?? "-"}
                  </td>
                  <td className="p-4 border-b whitespace-nowrap">
                    {state?.subRetailer?.referralCode ?? "-"}
                  </td>
                  <td className="p-4 border-b whitespace-nowrap">
                    {state?.subRetailer?.name ?? "-"}
                  </td>
                  <td className="p-4 border-b whitespace-nowrap">
                    {state?.sourceTransaction?.type ?? "-"}
                  </td>
                  <td className="p-4 border-b whitespace-nowrap">
                    {state?.pincode ?? "-"}
                  </td>
                  <td className="p-4 border-b whitespace-nowrap">
                    {formatDate(state?.createdAt)}
                  </td>
                  <td
                    className={`p-4 border-b whitespace-nowrap italic font-semibold ${
                      state?.status === "COMPLETED"
                        ? "text-green-500"
                        : state?.status === "PENDING"
                        ? "text-yellow-500"
                        : "text-red-500"
                    }`}
                  >
                    {state?.status}
                  </td>
                  <td className="p-4 border-b whitespace-nowrap">
                    {state?.fundAmount ?? "0.00"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="min-h-96 w-full flex flex-col justify-center items-center font-suse">
          <LeafyGreenIcon className="w-6 h-6" />
          <p>Commissions not found.</p>
        </div>
      )}

      {totalPages > 1 && (
        <div className="mt-6">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
};

export default CommissionTable;
