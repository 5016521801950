import { httpAxios } from "../../helper/httpHelper";

export async function getStateActivities(user) {
  try {
    const { data } = await httpAxios.get(`/retailer/dashboard/state/metrics`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${user}`,
      },
    });

    return data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch subscribers"
    );
  }
}

export async function getStateDonutChartData(user) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/dashboard/state/commission/statistics`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch subscribers"
    );
  }
}

export async function getStateLineChartData(user, year) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/dashboard/state/commission/analytics?year=${year}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch subscribers"
    );
  }
}
