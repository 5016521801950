import React from "react";
import { vipIds } from "../../array/vipIdsArr";
import { Pencil, PencilLine } from "lucide-react";
import { formatDateMonthYear } from "../../utils/formatTime";

const VipIdTable = ({
  vipUsers,
  currentPage,
  limit,
  setEdit,
  selectedId,
  setSelectedId,
}) => {
  // console.log("vipUsers", vipUsers?.data?.docs);

  const handleSelect = (vipUsers) => {
    setSelectedId(vipUsers);
    setEdit("edit");
  };

  return (
    <>
      <div className="overflow-x-auto overflow-y-hidden">
        <div className="align-middle inline-block min-w-full">
          <div className="overflow-hidden border border-custom-border">
            <table className="min-w-full">
              <thead className="bg-sub-card border-b border-custom-border bg-gradient-to-tr from-custom-border to-bg-color text-white">
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                  >
                    Serial
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                  >
                    VIP ID
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                  >
                    Member Name
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                  >
                    Created Date
                  </th>
                  {/* <th
                    scope="col"
                    className="px-4 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                  >
                    Action
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {vipUsers?.data?.docs?.map((vip, id) => (
                  <tr
                    key={id}
                    className={`${
                      id % 2 === 0 ? "bg-white" : "bg-bg-color/10"
                    } ${selectedId?.id === vip?.id ? "bg-black/10" : ""}`}
                  >
                    <td className="px-4 py-4 whitespace-nowrap text-sm">
                      {id + 1 + (currentPage - 1) * limit}.
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm">
                      {vip?.referralCode ?? "Nill"}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm">
                      {vip?.name ?? "Nill"}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm">
                      {formatDateMonthYear(vip.createdAt)}
                    </td>
                    {/* <td className="px-4 py-4 whitespace-nowrap text-sm">
                      <button
                        title="Edit"
                        onClick={() => handleSelect(vip)}
                        className=""
                      >
                        <PencilLine
                          fill={selectedId?.id === vip?.id ? "green" : "none"} // Use 'currentColor' for better CSS control
                          className={`w-4 h-4 ${
                            selectedId?.id === vip?.id
                              ? "text-green-400"
                              : "text-emerald-500"
                          }`}
                        />
                      </button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default VipIdTable;
