export const serviceArr = [
  {
    service_name: "Transfer Funds",
    title: "Transfer",
    notice: "",
    logo: "/images/dashboard/Connectivity.png",
    route: "",
    modal_id: "transfer-fund",
    subsidiary_services: [
      {
        sub_service_name: "Transfer Records",
        sub_logo: "/images/dashboard/folder.png",
        sub_route: "/transfer-records",
      },
      {
        sub_service_name: "Transfer Now",
        sub_logo: "/images/dashboard/exchange.png",
        sub_route: "",
        modal_id: "transfer-now",
      },
    ],
  },
  {
    service_name: "Manage Sub-Retailer",
    title: "Manage",
    notice: "",
    logo: "/images/dashboard/Programmer.png",
    route: "",
    modal_id: "manage-sub",
    subsidiary_services: [
      {
        sub_service_name: "Sub-Retailer",
        sub_logo: "/images/dashboard/consultant.png",
        sub_route: "/manage-sub-retailer",
      },
      {
        sub_service_name: "Manage Offer Benefits",
        sub_logo: "/images/dashboard/offer.png",
        sub_route: "/manage-offer-benefits",
      },
    ],
  },
  {
    service_name: "Manage States",
    title: "Manage",
    notice: "",
    logo: "/images/dashboard/Programmer.png",
    route: "/manage-state-retailer",
    subsidiary_services: [],
  },
  {
    service_name: "Manage Country Commissions",
    title: "Commissions",
    notice: "",
    logo: "/images/dashboard/commission.png",
    route: "/manage-country-commissions",
    subsidiary_services: [],
  },
  // {
  //   service_name: "Manage Retailer",
  //   title: "Retailer",
  //   notice: "",
  //   logo: "/images/dashboard/Programmer.png",
  //   route: "/manage-retailer",
  //   subsidiary_services: [],
  // },
  {
    service_name: "Manage State Commissions",
    title: "Commissions",
    notice: "",
    logo: "/images/dashboard/commission.png",
    route: "/manage-state-commissions",
    subsidiary_services: [],
  },
  {
    service_name: "Pending State / Division requests",
    title: "Requests",
    notice: "",
    logo: "/images/dashboard/pending.png",
    route: "/pending-requests",
    subsidiary_services: [],
  },
  {
    service_name: "Accepted State / Division Report",
    title: "Requests",
    notice: "",
    logo: "/images/dashboard/accepted.png",
    route: "/accepted-reports",
    subsidiary_services: [],
  },
  {
    service_name: "BC Insurance",
    title: "",
    notice: "Coming soon",
    logo: "/images/dashboard/insurance.png",
    route: "/bc-insurance",
    subsidiary_services: [],
  },
  {
    service_name: "Generate VIP ID's",
    title: "",
    notice: "",
    logo: "/images/dashboard/vip-card.png",
    route: "/generate-vip-id",
    subsidiary_services: [],
  },
  {
    service_name: "Manage Advertisement",
    title: "",
    notice: "",
    logo: "/images/dashboard/ads.png",
    route: "/manage-ads",
    subsidiary_services: [],
  },
  {
    service_name: "Manage VIP Subscriptions",
    title: "",
    notice: "Coming soon",
    logo: "/images/dashboard/Vip.png",
    route: "/manage-vip-subscriptions",
    subsidiary_services: [],
  },
  {
    service_name: "Nutrition Product Bazaar",
    title: "",
    notice: "Coming soon",
    logo: "/images/dashboard/Vitamins.png",
    route: "/product-bazar",
    subsidiary_services: [],
  },
  {
    service_name: "Driver Registration",
    title: "",
    notice: "Coming soon",
    logo: "/images/dashboard/Driver.png",
    route: "/driver-register",
    subsidiary_services: [],
  },
  {
    service_name: "Crypto Consultation Booking",
    title: "",
    notice: "Coming soon",
    logo: "/images/dashboard/Booking.png",
    route: "/crypto-consult-booking",
    subsidiary_services: [],
  },
  {
    service_name: "Courier Service",
    title: "",
    notice: "Coming soon",
    logo: "/images/dashboard/man.png",
    route: "/courier-service",
    subsidiary_services: [],
  },
  {
    service_name: "Service Based",
    title: "",
    notice: "Coming soon",
    logo: "/images/dashboard/services.png",
    route: "/services-based",
    subsidiary_services: [],
  },
  {
    service_name: "Mining Node Booking",
    title: "",
    notice: "Coming soon",
    logo: "/images/dashboard/mining.png",
    route: "/mining-node-booking",
    subsidiary_services: [],
  },
  {
    service_name: "Prepaid Card Registration",
    title: "",
    notice: "Coming soon",
    logo: "/images/dashboard/Visa.png",
    route: "/prepaid-card-register",
    subsidiary_services: [],
  },
  {
    service_name: "Burn-out ID Restoration",
    title: "",
    notice: "",
    logo: "/images/dashboard/Alert.png",
    route: "/burn-id-restore",
    subsidiary_services: [],
  },
  {
    service_name: "Crypto Sound Box",
    title: "",
    notice: "Coming soon",
    logo: "/images/dashboard/box.png",
    route: "/souund-box",
    subsidiary_services: [],
  },
  {
    service_name: "Reseller Registration",
    title: "",
    notice: "Coming soon",
    logo: "/images/dashboard/reseller.png",
    route: "/reseller-register",
    subsidiary_services: [],
  },
  {
    service_name: "Statement Downloads",
    title: "",
    notice: "",
    logo: "/images/dashboard/Password.png",
    route: "",
    modal_id: "statement-download-modal",
    subsidiary_services: [],
  },
  {
    service_name: "Request for a State / Division",
    title: "",
    notice: "",
    logo: "/images/dashboard/Password.png",
    route: "",
    modal_id: "request-state-modal",
    subsidiary_services: [],
  },
];
