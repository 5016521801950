import React from "react";
import { useAppContext } from "../../../context/AppContext";
import { formatDate } from "../../../utils/formatTime";

const PendingStateRequestTable = ({
  data,
  currentPage,
  limit,
  handleAction,
}) => {
  const { setModal } = useAppContext();

  return (
    <>
      <div className="mt-2 font-suse">
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto overflow-y-hidden">
            <div className="align-middle inline-block min-w-full">
              <div className="overflow-hidden sm:rounded-xl border border-custom-border">
                <table className="min-w-full">
                  <thead className="bg-sub-card border-b  border-custom-border bg-gradient-to-tr from-custom-border to-bg-color text-white">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                      >
                        Sl. No.
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                      >
                        Retailer Member ID
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                      >
                        Retailer Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                      >
                        Retailer Email
                      </th>

                      <th
                        scope="col"
                        className="px-6 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                      >
                        Date Time
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                      >
                        Admin Status
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.docs?.map((req, index) => (
                      <tr
                        key={index}
                        className={
                          index % 2 === 0 ? "bg-white" : "bg-bg-color/10"
                        }
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm flex items-center gap-1.5">
                          {index + 1 + (currentPage - 1) * limit}.
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          {req?.user?.referralCode ?? "-"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          {req?.user?.name ?? "-"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          {req?.user?.email ?? "-"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          {formatDate(req.createdAt)}
                        </td>
                        <td
                          className={`px-6 py-4 whitespace-nowrap text-sm italic font-semibold ${
                            req.adminStatus === "COMPLETED"
                              ? "text-emerald-500"
                              : req.adminStatus === "PENDING"
                              ? "text-yellow-500"
                              : "text-red-500"
                          }`}
                        >
                          {req.adminStatus ?? "-"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          <div>
                            {req.stateStatus === "PENDING" && (
                              <div className="w-auto space-x-4">
                                <button
                                  onClick={() => handleAction("accept", req)}
                                  className="p-1.5 px-4 bg-emerald-500 hover:bg-emerald-500/80 rounded-lg text-white"
                                >
                                  Accept
                                </button>
                                <button
                                  onClick={() => handleAction("reject", req)}
                                  className="p-1.5 px-4 bg-red-500 hover:bg-red-500/80 rounded-lg text-white"
                                >
                                  Reject
                                </button>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PendingStateRequestTable;
