import React, { useState } from "react";
import MainLayout from "../components/Layouts/MainLayout";
import {
  ArchiveRestore,
  CircleAlert,
  CircleChevronLeft,
  RefreshCcw,
  Search,
  User2,
} from "lucide-react";
import { Link } from "react-router-dom";
import Pagination from "../components/Pagination";
import BurnRestoreTable from "../components/Tables/BurnRestoreTable";
import { useMutation, useQuery } from "react-query";
import { getUser } from "../services/auth";
import {
  checkout,
  getDeletedIds,
  restoredIdHistory,
} from "../services/burnIdRestoreServices";
import { useAppContext } from "../context/AppContext";
import { toast } from "react-toastify";
import CheckoutModal from "../components/Modals/CheckoutModal";
import RestoreBurnIdModal from "../components/Modals/RestoreBurnIdModal";

const BurnOutIdRestoreScreen = () => {
  const [selectedUser, setSelectedUser] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [checkoutData, setCheckoutData] = useState(null);

  const limit = 10;

  const { modal, setModal } = useAppContext();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to page 1 when search query changes
  };

  // console.log("selectedUser", selectedUser);

  // Fetch Deleted Account
  const { data: restoredIds, refetch: fetchRestoredIds } = useQuery({
    queryKey: ["restoredIds", currentPage, limit],
    queryFn: async () => {
      const user = await getUser();
      const res = await restoredIdHistory(user, currentPage, limit);
      return res.data;
    },
    onSuccess: (data) => {
      if (data) {
        setTotalPages(data?.totalPages || 1);
      } else {
        console.warn(data?.message || "An error occurred.");
      }
    },
    onError: (data) => {
      console.error(data);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  // console.log("restoredIds", restoredIds);

  const {
    data: deletedIds,
    isLoading,
    refetch: triggerFetch,
  } = useQuery({
    queryKey: ["deleredIds", searchQuery],
    queryFn: async () => {
      const user = await getUser();
      const res = await getDeletedIds(user, searchQuery);
      return res.data;
    },
    onSuccess: (data) => {
      if (data) {
        setSelectedUser(data?.docs[0]);
      } else {
        setSelectedUser({});
      }
    },
    onError: (data) => {
      console.error(data);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: !!searchQuery,
  });

  // console.log("deletedIds", deletedIds);

  // For Checkout Mutation
  const checkoutMutation = useMutation(
    async () => {
      const user = await getUser();
      return await checkout(user);
    },
    {
      onSuccess: (data) => {
        if (data) {
          setModal("checkout");
          setCheckoutData(data.data);
        } else {
          toast.error("An error occurred during checkout.");
        }
      },
      onError: (error) => {
        console.error(error);
        toast.error("An error occurred");
      },
    }
  );

  // console.log("selectedUser", selectedUser);

  return (
    <>
      {modal === "checkout" && checkoutData !== null && (
        <CheckoutModal
          checkoutData={checkoutData}
          selectedUser={selectedUser}
        />
      )}
      {modal === "confirm" && (
        <RestoreBurnIdModal
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          checkoutData={checkoutData}
          setCheckoutData={setCheckoutData}
          fetchRestoredIds={fetchRestoredIds}
          triggerFetch={triggerFetch}
        />
      )}
      <MainLayout>
        <div>
          <div className="pb-4">
            <Link
              to="/dashboard"
              className="flex  items-center gap-2 bg-custom-border/20 hover:bg-custom-border/30 p-2 w-fit rounded-xl"
            >
              <CircleChevronLeft className="w-4 h-4" />
              <h1 className="font-semibold text-sm">Burn-out Restoration</h1>
            </Link>
          </div>

          <div className="h-full flex flex-col lg:flex-row justify-between gap-6">
            <div className="w-full lg:w-5/12 h-full">
              <div className="flex items-center gap-2 p-1 h-12 border rounded-full shadow-md">
                <span className="ml-1">
                  <Search className="w-6 h-6 text-gray-500 " />
                </span>
                <input
                  value={searchQuery}
                  onChange={handleSearchChange}
                  type="search"
                  placeholder="Search"
                  className="w-full h-full outline-none text-sm p-2 "
                />
                <div className="">
                  <button className="bg-bg-color w-16 h-full p-2 rounded-full text-white">
                    GO
                  </button>
                </div>
              </div>

              <div className="mt-4 w-full h-full p-4 border rounded-xl shadow-lg">
                {restoredIds?.docs?.length > 0 ? (
                  <BurnRestoreTable
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                    restoredIds={restoredIds}
                    currentPage={currentPage}
                    limit={limit}
                  />
                ) : (
                  <div className="h-96 w-full flex flex-col justify-center items-center gap-1 text-sm">
                    <span className="flex justify-center items-center w-10 h-10 bg-gray-200 rounded-lg">
                      <ArchiveRestore className="w-6 h-6 text-gray-500 " />
                    </span>
                    <p>User Not Found.</p>
                  </div>
                )}
                <div className="mt-4">
                  {totalPages > 1 && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="w-full lg:w-7/12 h-full lg:mt-16 shadow-lg sm:p-4 border rounded-xl">
              {deletedIds?.docs?.length > 0 ? (
                <div className=" border p-4 sm:p-8 rounded-xl w-full h-full">
                  <div className="w-fit">
                    <div className="flex gap-4 items-center">
                      <div className="border-4 border-gray-300 rounded-full w-32 h-32 p-2 flex justify-center items-center relative">
                        {deletedIds?.docs[0]?.kyc?.userSelfie ? (
                          <div>
                            <img
                              src={deletedIds?.docs[0]?.kyc?.userSelfie}
                              className="w-full h-full rounded-full "
                            />
                          </div>
                        ) : (
                          <User2 className="w-full h-full text-gray-300" />
                        )}
                        <div className="absolute -rotate-45 font-semibold italic text-orange-700 w-full text-center">
                          {deletedIds?.docs[0]?.isPermanentlyDeleted ===
                            false && <p className="text-xl">Retored</p>}
                        </div>
                      </div>
                    </div>
                    <p className="text-center mt-4 font-semibold ">
                      {deletedIds?.docs[0]?.member_name}
                    </p>
                  </div>
                  <div className="w-full h-full mt-8 flex flex-wrap gap-6">
                    <div className="w-full sm:w-fit">
                      <h1>Member ID</h1>
                      <input
                        className="border p-2 rounded-xl mt-1 outline-none w-full"
                        value={deletedIds?.docs[0]?.referralCode || ""}
                        readOnly
                      />
                    </div>
                    <div className="w-full sm:w-fit">
                      <h1>Email ID</h1>
                      <input
                        className="border p-2 rounded-xl mt-1 outline-none w-full"
                        value={deletedIds?.docs[0]?.email || ""}
                        readOnly
                      />
                    </div>
                    <div className="w-full sm:w-fit">
                      <h1>Mobile Number</h1>
                      <input
                        className="border p-2 rounded-xl mt-1 outline-none w-full"
                        value={deletedIds?.docs[0]?.mobile || ""}
                        readOnly
                      />
                    </div>
                    <div className="w-full sm:w-fit">
                      <h1>Current Balance</h1>
                      <input
                        className="border p-2 rounded-xl mt-1 outline-none w-full"
                        value={
                          deletedIds?.docs[0]?.stockBalance?.toFixed(2) ||
                          "0.00"
                        }
                        readOnly
                      />
                    </div>
                    {/* <div>
                      {deletedIds?.docs[0]?.restoreHistory.length > 0 && (
                        <div className="w-full sm:w-fit">
                          <h1>Current Balance</h1>
                          <input
                            className="border p-2 rounded-xl mt-1 outline-none w-full"
                            value={
                              (deletedIds?.docs[0]??.stockBalance).toFixed(2) || "0.00"
                            }
                            readOnly
                          />
                        </div>
                      )}
                    </div> */}
                  </div>
                  <div className="mt-12 flex justify-end items-center">
                    {deletedIds?.docs[0]?.isPermanentlyDeleted && (
                      <button
                        onClick={() => checkoutMutation.mutate()}
                        disabled={checkoutMutation.isLoading}
                        className={`p-2 px-4 ${
                          checkoutMutation.isLoading
                            ? "bg-gray-400"
                            : "bg-orange-700 hover:bg-opacity-85"
                        } text-white rounded-xl flex justify-center items-center gap-2`}
                      >
                        <RefreshCcw className="w-4 h-4" />
                        {checkoutMutation.isLoading
                          ? "Processing..."
                          : "Restore"}
                      </button>
                    )}
                  </div>
                </div>
              ) : deletedIds?.docs?.length === 0 && searchQuery !== "" ? (
                <div className="min-h-64 h-full w-full flex flex-col justify-center items-center text-sm gap-2 text-center">
                  <CircleAlert />
                  <p>User Not Found.</p>
                </div>
              ) : (
                <div className="min-h-64 h-full w-full flex flex-col justify-center items-center text-sm gap-2 text-center">
                  <CircleAlert />
                  <p>Search Member to view Profile Details</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default BurnOutIdRestoreScreen;
