import React, { memo, useRef, useState } from "react";
import ModalLayout from "../../Layouts/ModalLayout";
import { Trash, X } from "lucide-react";
import { updateRecharge } from "../../../services/dashboardServices";
import { toast } from "react-toastify";
import { getUser } from "../../../services/auth";
import { useAppContext } from "../../../context/AppContext";

const SubmitRechargeModal = ({ setModal, rechargeDetails }) => {
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [txnId, setTxnId] = useState("");
  const [error, setError] = useState("");

  const { country } = useAppContext();

  const fileInputRef = useRef(null);

  // console.log(rechargeDetails);

  const handleUploadClick = () => fileInputRef.current.click();

  const handleUpdatePendingRecharge = async () => {
    try {
      const formData = new FormData();
      formData.append("transactionId", txnId);
      formData.append("transactionReceipt", file);
      formData.append("id", rechargeDetails._id);

      const user = await getUser();
      const result = await updateRecharge(user, formData);
      if (result.status) {
        toast.success(result.message);
        setModal("uploaded");
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    const maxSize = 400 * 1024; // 400 KB in bytes

    if (file) {
      if (!allowedTypes.includes(file.type)) {
        setError("Only PNG, JPEG, and JPG files are allowed.");
        setFile(null);
        setFileName("");
        return;
      }

      if (file.size > maxSize) {
        setError("File size must be under 400 KB.");
        setFile(null);
        setFileName("");
        return;
      }

      setFile(file);
      setFileName(file.name);
      setError("");
    }
  };

  const handleDelete = () => {
    setFileName("");
    setFile(null);
    setError("");
    if (fileInputRef.current) fileInputRef.current.value = null;
  };

  return (
    <>
      <ModalLayout maxWidth="max-w-96">
        <div className="bg-white w-full h-full ">
          <div className="modal-header">
            <div className="flex-1 flex"></div>
            <div className="modal-header-body2">Submit Receipt</div>
            <div onClick={() => setModal("")} className="modal-header-body">
              <X style={{ cursor: "pointer" }} />
            </div>
          </div>
          <div className="p-4 relative space-y-3">
            <div className="flex justify-center items-center">
              <h1 className="flex-1">Amount</h1>
              <div className="flex flex-1 items-center gap-1.5 ">
                <div className="flex flex-1 items-center gap-1.5 ">
                  <img
                    src={country?.currencySymbol}
                    alt="symbol"
                    width={12}
                    height={12}
                    title={country?.currencyCode}
                    style={{ marginRight: "8px" }}
                  />
                  <p className="">{rechargeDetails?.price}</p>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center">
              <h1 className="flex-1">Transaction ID</h1>
              <div className="flex flex-1 items-center gap-1.5 ">
                <input
                  value={txnId}
                  onChange={(e) => setTxnId(e.target.value)}
                  title="txnID"
                  name="TXNID"
                  type="text"
                  className="border border-custom-border/30 h-10 rounded-lg w-full px-2 outline-none"
                />
              </div>
            </div>
            <div className="flex">
              <h1 className="flex-1">Upload Receipt</h1>
              <div className="flex flex-col flex-1 gap-1.5 ">
                <input
                  type="text"
                  value={fileName}
                  readOnly
                  placeholder="No file selected"
                  className="border border-custom-border/30 h-10 rounded-lg w-full px-2 outline-none"
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  accept=".png,.jpeg,.jpg"
                />
                <div className="mt-2 w-full flex gap-1">
                  <button
                    className="bg-bg-color w-32 py-2 rounded-lg text-sm text-white"
                    onClick={handleUploadClick}
                  >
                    Upload
                  </button>
                  {fileName && (
                    <button
                      onClick={handleDelete}
                      type="button"
                      className=" bg-red-100 flex justify-center items-center w-10 rounded-md border border-red-500"
                    >
                      <Trash className="w-4 h-4" stroke="red" />
                    </button>
                  )}
                </div>
                <div style={{ fontSize: "12px", marginTop: "5px" }}>
                  {error && <p style={{ color: "red" }}>{error}</p>}
                </div>
              </div>
            </div>
            <div className="pt-12">
              <button
                onClick={handleUpdatePendingRecharge}
                type="button"
                className="w-full bg-bg-color hover:bg-bg-color/80 h-10 rounded-xl text-white"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default memo(SubmitRechargeModal);
