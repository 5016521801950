import React, { memo } from "react";
import ModalLayout from "../../Layouts/ModalLayout";
import { useAppContext } from "../../../context/AppContext";

const TestModal = ({ subsidiaryServices, onSubServiceClick }) => {
  const { userData } = useAppContext();

  // Filter subsidiary services based on user role
  const filteredServices = subsidiaryServices?.subsidiary_services?.filter(sub => {
    // If user is RETAILER, show all services
    if (userData.role === "RETAILER") {
      return true;
    }
    // If user is not RETAILER, hide Sub-Retailer service
    return sub.sub_service_name !== "Sub-Retailer";
  });

  return (
    <ModalLayout maxWidth="max-w-fit">
      <div className="p-4 bg-white rounded-xl w-full h-full">
        <h1 className="font-semibold text-center text-xl py-2">
          {subsidiaryServices?.title}
        </h1>
        <div className="pb-2 pt-8 grid grid-cols-2 gap-4">
          {filteredServices?.map((sub, id) => (
            <div
              key={id}
              className="flex flex-col items-center gap-2 border shadow-lg p-4 rounded-xl hover:bg-bg-color/20 hover:shadow-none transition-all duration-200 cursor-pointer"
              onClick={() => onSubServiceClick(sub)}
            >
              <img
                src={sub.sub_logo}
                alt="logo"
                loading="lazy"
                width={55}
                height={55}
              />
              <p className="text-sm text-center">{sub.sub_service_name}</p>
            </div>
          ))}
        </div>
      </div>
    </ModalLayout>
  );
};

export default memo(TestModal);