import { httpAxios } from "../helper/httpHelper";

export async function createOfferBenefit(user, formData) {
  try {
    // I can't use this "Content-Type": "application/json" because i send formData directly.
    const { data } = await httpAxios.post(`/retailer/offer/add`, formData, {
      headers: {
        Authorization: `${user}`, // Use only the authorization header
      },
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchOfferBenefits(user, page, limit) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/offer/list?page=${page}&limit=${limit}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchOfferBenefitsForSubRetailers(user, page, limit) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/offer/list/sub-retailer?page=${page}&limit=${limit}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function deleteOfferBenefit(user, offerId) {
  try {
    const { data } = await httpAxios.delete(`/retailer/offer/delete`, {
      params: { offerId }, // Sending offerId as a query param
      headers: {
        Authorization: `${user}`,
      },
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function disableOfferBenefit(user, offerId) {
  try {
    const { data } = await httpAxios.put(
      `/retailer/offer/update/disable`,
      {
        offerId: offerId,
      },
      {
        headers: {
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function enableOfferBenefit(user, offerId) {
  try {
    const { data } = await httpAxios.put(
      `/retailer/offer/update/enable`,
      {
        offerId: offerId,
      },
      {
        headers: {
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}
