import React from "react";
import ModalLayout from "../Layouts/ModalLayout";
import { RotateCcw, X } from "lucide-react";
import { useAppContext } from "../../context/AppContext";
import { getUser } from "../../services/auth";
import { retore } from "../../services/burnIdRestoreServices";
import { toast } from "react-toastify";
import { useMutation } from "react-query";

const RestoreBurnIdModal = ({
  selectedUser,
  setSelectedUser,
  checkoutData,
  setCheckoutData,
  fetchRestoredIds,
  triggerFetch,
}) => {
  const { setModal } = useAppContext();

  // Mutation to handle the restore functionality
  const restoreMutation = useMutation(
    async () => {
      const user = await getUser();
      return await retore(user, checkoutData._id, selectedUser.id);
    },
    {
      onSuccess: (res) => {
        if (res?.status) {
          toast.success(res.message);
          setModal("");
          setSelectedUser({});
          setCheckoutData(null);
          fetchRestoredIds();
          triggerFetch();
        } else {
          toast.error(res.message);
        }
      },
      onError: (error) => {
        console.error(error);
        toast.error("An error occurred during the restore operation.");
      },
    }
  );

  return (
    <>
      <ModalLayout maxWidth="max-w-sm">
        <div>
          <div className="flex justify-between items-center p-4 border-b">
            <h1 className="text-lg font-semibold">Confirmation</h1>
            <button
              onClick={() => setModal("")}
              className="hover:bg-gray-200 hover:text-red-600 p-1 rounded-full"
            >
              <X />
            </button>
          </div>
          <div className="flex flex-col justify-center items-center p-4">
            <div className="text-center py-12">
              Are you sure you want to{" "}
              <span className="text-red-500 font-semibold">Restore</span> this
              account?
            </div>
            <div className="mt-4 flex gap-4 justify-center w-full">
              <button
                onClick={() => setModal("")}
                className="max-w-44 w-full border rounded-xl p-2 px-4"
              >
                Cancel
              </button>
              <button
                onClick={() => restoreMutation.mutate()}
                disabled={restoreMutation.isLoading}
                className={`max-w-44 w-full ${
                  restoreMutation.isLoading
                    ? "bg-gray-400"
                    : "bg-pink-500 hover:bg-opacity-85"
                } p-2 px-4 rounded-xl text-white flex justify-center items-center gap-2`}
              >
                <RotateCcw className="w-5 h-5" />
                {restoreMutation.isLoading ? "Restoring..." : "Restore"}
              </button>
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default RestoreBurnIdModal;
