import { httpAxios } from "../helper/httpHelper";

export async function getFunds(user) {
  try {
    const { data } = await httpAxios.get(`/retailer/dashboard/fund/get`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${user}`,
      },
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function addFundGraph(user, year) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/dashboard/fund/add/graph/get?year=${year}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function transferFundGraph(user, year) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/dashboard/fund/transfer/graph/get?year=${year}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getPaymentLinks(user) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/wallet/payment-links/list`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function recharge(user, userID) {
  try {
    const { data } = await httpAxios.post(
      `/retailer/wallet/add-fund/request/create`,
      {
        beneficiaryId: userID,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getPendingRecharges(user, status, currPage, pageLimit) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/wallet/add-fund/request/list?status=${status}&page=${currPage}&limit=${pageLimit}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function searchUserById(user, searchQuery) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/user/get?search=${searchQuery}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function searchRetailersById(user, searchQuery) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/state-user/retailer/get?search=${searchQuery}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function transferAmount(user, userId, amount) {
  try {
    const { data } = await httpAxios.post(
      `/retailer/wallet/transfer/create`,
      {
        userId,
        amount,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function transferAmountToRetailer(user, userId, amount) {
  try {
    const { data } = await httpAxios.post(
      `/retailer/state-user/retailer/transfer/create`,
      {
        userId,
        amount,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchTransactions(
  user,
  page,
  limit,
  status,
  date,
  search
) {
  try {
    const params = {
      page: page,
      limit: limit,
    };

    if (status) params.status = status;
    if (date) params.date = date;
    if (search) params.search = search;

    const { data } = await httpAxios.get(`/retailer/wallet/transfer/list`, {
      params: params,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${user}`,
      },
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchFundHistory(
  user,
  page,
  limit,
  status,
  date,
  search
) {
  try {
    const params = {
      page: page,
      limit: limit,
    };

    if (status) params.status = status;
    if (date) params.date = date;
    if (search) params.search = search;

    const { data } = await httpAxios.get(
      `/retailer/wallet/add-fund/request/list`,
      {
        params: params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchCountryCommission(
  user,
  page,
  limit,
  status,
  date,
  search
) {
  try {
    const params = {
      page: page,
      limit: limit,
    };

    if (status) params.status = status;
    if (date) params.date = date;
    if (search) params.search = search;

    const { data } = await httpAxios.get(
      `/retailer/dashboard/country/commission/list`,
      {
        params: params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchStateCommission(
  user,
  page,
  limit,
  status,
  date,
  search
) {
  try {
    const params = {
      page: page,
      limit: limit,
    };

    if (status) params.status = status;
    if (date) params.date = date;
    if (search) params.search = search;

    const { data } = await httpAxios.get(
      `/retailer/dashboard/state/commission/list`,
      {
        params: params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchRetailerCommission(
  user,
  page,
  limit,
  status,
  date,
  search
) {
  try {
    const params = {
      page: page,
      limit: limit,
    };

    if (status) params.status = status;
    if (date) params.date = date;
    if (search) params.search = search;

    const { data } = await httpAxios.get(
      `/retailer/dashboard/retailer/commission/list`,
      {
        params: params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function exportFundsCSV(user) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/wallet/add-fund/request/export`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
        responseType: "blob",
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function exportCountryCommissionCSV(user) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/dashboard/country/commission/export`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
        responseType: "blob",
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function exportStateCommissionCSV(user) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/dashboard/state/commission/export`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
        responseType: "blob",
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function exportRetailerCommissionCSV(user) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/dashboard/retailer/commission/export`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
        responseType: "blob",
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function exportTxnCSV(user) {
  try {
    const { data } = await httpAxios.get(`/retailer/wallet/transfer/export`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${user}`,
      },
      responseType: "blob",
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function updateRecharge(user, formData) {
  try {
    const { data } = await httpAxios.put(
      `/retailer/wallet/add-fund/request/update`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function userTransferHistory(user, userId, page, limit) {
  try {
    const params = {
      userId: userId,
      page: page,
      limit: limit,
    };

    const { data } = await httpAxios.get(`/retailer/wallet/transfer/list`, {
      params: params,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${user}`,
      },
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getNetworkLists(user) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/wallet/recharge/crypto/platform/list?symbol=USDT`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getLiveUSDTPrice(user) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/wallet/recharge/crypto/price/get?symbol=USDT`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function createRechargeReq(
  user,
  amount,
  finalPrice,
  cryptoPlatform
) {
  try {
    const params = {
      amount: amount,
      finalPrice: finalPrice,
      cryptoPlatformName: cryptoPlatform.name,
      cryptoPlatformSymbol: cryptoPlatform.symbol,
    };

    const { data } = await httpAxios.post(
      `/retailer/wallet/recharge/crypto`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function verifyTxnHash(user, transactionId, cryptoPlatformSymbol) {
  try {
    const params = {
      transactionId: transactionId,
      cryptoPlatformSymbol: cryptoPlatformSymbol,
    };

    const { data } = await httpAxios.post(
      `/retailer/wallet/recharge/crypto/hash/verify`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function submitTxnHash(user, id, txnHash) {
  try {
    const params = {
      id: id,
      transactionId: txnHash,
    };

    const { data } = await httpAxios.post(
      `/retailer/wallet/recharge/crypto/hash`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getAvailableRecharge(user) {
  try {
    const { data } = await httpAxios.get(`/retailer/wallet/recharge/config`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${user}`,
      },
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
}
