import React, { memo } from "react";
import ModalLayout from "../../Layouts/ModalLayout";

const SubmitSucceedModal = ({ setModal }) => {
  return (
    <>
      <ModalLayout>
        <div className="fund-modal-container">
          <div>
            <div className="loading-gif">
              <img
                src="https://s3-alpha-sig.figma.com/img/0693/248d/1fe567baf7581187ccc17c3c27f4f276?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=JhA5ixyNguud8D1uuiKZHsO9va549ajVHVg4Ndml0b-3aHdQ21Iq7d6fFgxMTpmo~wWFuY10sGkdNXhlsVXCE9B0SrE5oep-AqfpgTBNFrJOGhfR5f7hNxuNkbEjICIya10gw17WYjyzIHevm86-oXTDxMh~NtmeSlwdL50mfAi-QO-mdCdd4ckpUIkJdhI6JPpHmAVJNLnIsyPee~i0qK3e6J-k3AomwQ~JESuX5SF~6SoJ8Ax~YoZXPKp2TfidPciooA~x1f9z7N00RKgLRL4m3S9et2fYA5oWOeMzNXbPhD1Zv~MHWOP3aRZr0rlJpfioAdT1oJFxNWIAG5b7xA__"
                alt="gif"
                loading="lazy"
              />
            </div>
            <div
              style={{ textAlign: "center", color: "rgba(1, 166, 57, 1)" }}
            >
              <p>Receipt Submitted</p>
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default memo(SubmitSucceedModal);
