import { httpAxios } from "../../helper/httpHelper";

export async function getCountryActivities(user) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/dashboard/country/metrics`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch subscribers"
    );
  }
}

export async function getCountryDonutChartData(user) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/dashboard/country/commission/statistics`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch subscribers"
    );
  }
}

export async function getCountryLineChartData(user, year) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/dashboard/country/commission/analytics?year=${year}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch subscribers"
    );
  }
}

export async function getStateLists(user) {

  try {
    const { data } = await httpAxios.get(
      `/retailer/configuration/state/list`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch subscribers"
    );
  }
}


