import React, { useState } from "react";
import MainLayout from "../../components/Layouts/MainLayout";
import { serviceArr } from "../../array/serviceArr";
import TestModal from "../../components/Modals/DashboardModals/TestModal";
import StatementDownloadModal from "../../components/Modals/StatementDownloadModal";
import { useAppContext } from "../../context/AppContext";
import { Loader, Rss } from "lucide-react";
import SearchUserModal from "../../components/Modals/TransferFunds/SearchUserModal";
import RequestStateModal from "../../components/Modals/RequestStateModal";
import CountryStats from "../../components/DashboardComponents/Country/CountryStats";
import RetailerStats from "../../components/DashboardComponents/Retailer/RetailerStats";
import StateStats from "../../components/DashboardComponents/State/StateStats";

const DashboardScreen = () => {
  const { modal, setModal, userData } = useAppContext();
  const [subsidiaryServices, setSubsidiaryServices] = useState({});

  const handleServiceClick = (service) => {
    setModal("");
    if (service.modal_id) {
      setSubsidiaryServices(service);
      setModal(service.modal_id);
    } else if (service.route) {
      window.location.href = service.route;
    }
  };

  const handleSubServiceClick = (subService) => {
    setModal("");
    if (subService.modal_id) {
      setTimeout(() => setModal(subService.modal_id), 100);
    } else if (subService.sub_route) {
      window.location.href = subService.sub_route;
    }
  };

  const renderModal = () => {
    switch (modal) {
      case "manage-sub":
      case "transfer-fund":
        return (
          <TestModal
            subsidiaryServices={subsidiaryServices}
            onSubServiceClick={handleSubServiceClick}
          />
        );
      case "transfer-now":
        return <SearchUserModal setModal={setModal} />;
      case "statement-download-modal":
        return <StatementDownloadModal />;
      case "request-state-modal":
        return <RequestStateModal />;
      default:
        return null;
    }
  };

  const getVisibleServices = () => {
    if (userData?.role === "RETAILER") {
      return serviceArr.filter(
        (service) =>
          service.service_name === "Manage Sub-Retailer" ||
          service.service_name === "Request for a State / Division"
        // || service.service_name === "Transfer Funds"
      );
    }
    if (userData?.role === "SUB-RETAILER") {
      return serviceArr.filter(
        (service) =>
          ![
            "Manage Sub-Retailer",
            "Manage States",
            "Manage Country Commissions",
            "Manage State Commissions",
            // "Manage Retailer",
            "Accepted State / Division Report",
            "Request for a State / Division",
            "Pending State / Division requests",
          ].includes(service.service_name)
      );
    }
    if (userData?.role === "STATE") {
      return serviceArr.filter((service) =>
        [
          // "Manage Retailer",
          "Manage State Commissions",
          "Accepted State / Division Report",
          "Pending State / Division requests",
          "Transfer Funds",
        ].includes(service.service_name)
      );
    }
    if (userData?.role === "COUNTRY") {
      return serviceArr.filter((service) =>
        ["Manage States", "Manage Country Commissions"].includes(
          service.service_name
        )
      );
    }
    return [];
  };

  return (
    <>
      {renderModal()}
      <MainLayout>
        <div className="w-full flex flex-col sm:flex-row gap-4 justify-between ">
          <div className="flex items-center gap-3 ">
            <Rss className="w-10 h-10 text-bg-color" />
            <span>
              <h1 className="font-semibold text-lg sm:text-xl">Our Services</h1>
              <p className="text-xs sm:text-sm">
                These are our services for{" "}
                {userData?.role === "COUNTRY" ? (
                  <span className="font-semibold italic">Countries</span>
                ) : (
                  <span className="font-semibold italic">
                    {userData?.role
                      ?.toLowerCase()
                      .replace(/^\w/, (c) => c.toUpperCase())}
                    's{" "}
                  </span>
                )}
                .
              </p>
            </span>
          </div>
          {userData && userData.role === "SUB-RETAILER" && (
            <div className="w-full sm:w-fit flex justify-center items-center">
              <button
                onClick={() => setModal("banner")}
                className="w-full sm:w-fit bg-bg-color/20 hover:bg-bg-color/50 transition-all duration-200 p-2.5 rounded-xl"
              >
                <div className="flex justify-center items-center gap-2">
                  <img
                    src="/images/dashboard/banner.png"
                    alt="Banner"
                    loading="lazy"
                    className="w-6 h-6"
                  />
                  <p className=" text-black font-semibold">Offer Benefits</p>
                </div>
              </button>
            </div>
          )}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6 gap-4 mt-6">
          {getVisibleServices().map((service, id) => (
            <div
              key={id}
              className="relative shadow-lg rounded-xl space-y-4 p-4 flex flex-col items-center justify-evenly border hover:bg-bg-color/20 hover:shadow-none transition-all duration-200 cursor-pointer"
              onClick={() => handleServiceClick(service)}
            >
              {service.notice && (
                <div className="absolute top-1 right-1 bg-black/10 p-1 px-2 rounded-md flex justify-center items-center gap-1">
                  <Loader className="w-3 h-3 text-neutral-500" />
                  <p className="text-xs text-neutral-500 font-semibold uppercase">
                    {service.notice}
                  </p>
                </div>
              )}
              <img
                src={service.logo}
                alt={service.service_name}
                loading="lazy"
                width={55}
                height={55}
              />
              <p className="text-sm text-center">{service.service_name}</p>
            </div>
          ))}
        </div>
        <div className="mt-8">
          {userData.role === "RETAILER" && <RetailerStats />}
          {userData.role === "STATE" && <StateStats />}
          {userData.role === "COUNTRY" && <CountryStats />}
        </div>
      </MainLayout>
    </>
  );
};

export default DashboardScreen;
