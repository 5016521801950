import React, { useState, useEffect, useCallback } from "react";
import { CircleChevronLeft, CloudDownload, Search, X } from "lucide-react";
import { toast } from "react-toastify";
import Pagination from "../../components/Pagination";
import MainLayout from "../../components/Layouts/MainLayout";
import { getUser } from "../../services/auth";
import {
  exportCountryCommissionCSV,
  exportStateCommissionCSV,
  fetchCountryCommission,
  fetchStateCommission,
} from "../../services/dashboardServices";
import CountryCommissionTable from "../../components/Tables/Country/CountryCommissionTable";
import { Link } from "react-router-dom";
import StateCommissionTable from "../../components/Tables/State/StateCommissionTable";

const ManageStateCommissionsScreen = ({ setModal }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [status, setStatus] = useState("COMPLETED");
  const [date, setDate] = useState("");
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const limit = 10;

  //Export CSV File
  const handleExportFunds = async () => {
    try {
      const user = await getUser();
      const csvData = await exportStateCommissionCSV(user);

      const blob = new Blob([csvData], { type: "text/csv" });
      // console.log("blob", blob);
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.setAttribute("download", "StateCommissionList.csv");

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error(error);
      toast.error("Failed to export CSV.");
    }
  };

  const loadTransactions = useCallback(
    async (page, status, date, search) => {
      try {
        const user = await getUser();
        const result = await fetchStateCommission(
          user,
          page,
          limit,
          status,
          date,
          search
        );
        setData(result.data.docs);
        setTotalPages(result.data.totalPages);
      } catch (error) {
        console.error(error);
      }
    },
    [limit, setData, setTotalPages]
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    loadTransactions(currentPage, status, date, debouncedSearch);
  }, [currentPage, status, date, debouncedSearch, loadTransactions]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <MainLayout>
        <div className="lg:p-4">
          <div className="flex flex-col xl:flex-row justify-between gap-4 w-full">
            <div className="sm:w-fit h-full flex justify-center items-center">
              <Link
                to="/dashboard"
                className="font-suse flex gap-2 w-full h-11 items-center whitespace-nowrap rounded-xl bg-gray-100 hover:bg-gray-200 p-2"
              >
                <CircleChevronLeft className="w-5 h-5" />
                Manage Commissions
              </Link>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-4 justify-end items-end w-full xl:max-w-[58rem] gap-4 text-sm">
              <div className="flex gap-1 border border-custom-border/30 p-1 rounded-xl w-full xl:w-56 h-11">
                <span className="input-icon">
                  <Search style={{ width: "20px" }} />
                </span>
                <input
                  onChange={(e) => setSearch(e.target.value)}
                  type="text"
                  title="search"
                  name="Search"
                  placeholder="Search"
                  className="w-full outline-none"
                />
              </div>
              <div className="flex gap-1 border border-custom-border/30 p-1 rounded-xl w-full xl:w-56 h-11">
                <input
                  onChange={(e) => setDate(e.target.value)}
                  type="date"
                  title="search"
                  name="Date"
                  placeholder="Search"
                  className="outline-none w-full "
                />
              </div>
              <div className="flex gap-1 border border-custom-border/30 p-1 rounded-xl w-full xl:w-56 h-11">
                <select
                  onChange={(e) => setStatus(e.target.value)}
                  className="w-full outline-none"
                >
                  <option value="" disabled hidden>
                    Status
                  </option>
                  <option value="COMPLETED">COMPLETED</option>
                  <option value="PENDING">PENDING</option>
                </select>
              </div>
              <div className="flex gap-1 border border-custom-border/30 p-1 rounded-xl w-full xl:w-56 h-11">
                <button
                  disabled={data.length === 0}
                  onClick={handleExportFunds}
                  className=" h-full flex items-center justify-center gap-1 bg-bg-color disabled:bg-bg-color/50 disabled:cursor-not-allowed w-full rounded-lg px-2 text-white"
                >
                  <CloudDownload style={{ width: "16px" }} /> <span>|</span>CSV
                </button>
              </div>
            </div>
          </div>
          <div className="py-4 lg:mt-4">
            <StateCommissionTable
              data={data}
              currentPage={currentPage}
              limit={limit}
            />
          </div>
          <div className="flex justify-center items-center">
            {totalPages > 1 ? (
              <div className="pagination-container">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            ) : null}
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default ManageStateCommissionsScreen;
