import React from "react";
import MainLayout from "../../components/Layouts/MainLayout";

const ManageRetailerScreen = () => {
  return (
    <>
      <MainLayout>
        <div>ManageRetailerScreen</div>
      </MainLayout>
    </>
  );
};

export default ManageRetailerScreen;
