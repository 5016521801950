import React from "react";
import ModalLayout from "../../Layouts/ModalLayout";
import { X } from "lucide-react";
import { useAppContext } from "../../../context/AppContext";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { getUser } from "../../../services/auth";
import { acceptState } from "../../../services/state/retailerServices";

const AcceptStateModal = ({ selected, refetch }) => {
  const { setModal } = useAppContext();

  const acceptStateMutation = useMutation(
    async () => {
      const user = await getUser();
      return await acceptState(user, selected._id);
    },
    {
      onSuccess: (data) => {
        if (data) {
          setModal("");
          refetch();
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
      },
      onError: (error) => {
        console.error("Error accepting state:", error);
      },
    }
  );

  const handleAccept = () => {
    acceptStateMutation.mutate();
  };

  return (
    <>
      <ModalLayout maxWidth="max-w-sm">
        <div>
          <div className="flex justify-between items-center p-4 border-b">
            <h1 className="text-lg font-semibold">Accept State / Division</h1>
            <button
              onClick={() => setModal("")}
              className="hover:bg-gray-200 hover:text-red-600 p-1 rounded-full"
            >
              <X />
            </button>
          </div>

          <div className="flex flex-col justify-center items-center py-6 px-4">
            <div className="text-center py-12">
              Are you sure you want to{" "}
              <span className="text-emerald-500 font-semibold">Accept</span>{" "}
              this request ? This action cannot be undone.
            </div>
            <div className="mt-4 flex gap-4 justify-center w-full">
              <button
                onClick={() => setModal("")}
                className="max-w-44 w-full border rounded-xl p-2 px-4 hover:bg-black hover:text-white"
              >
                Cancel
              </button>
              <button
                onClick={handleAccept}
                disabled={acceptStateMutation.isLoading}
                className="max-w-44 w-full bg-emerald-600 hover:bg-opacity-85 p-2 px-4 rounded-xl text-white"
              >
                {acceptStateMutation.isLoading ? "Accepting..." : "Accept"}
              </button>
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default AcceptStateModal;
