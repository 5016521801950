// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transaction-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .transaction-table th,
  .transaction-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .transaction-table th {
    background-color: #000000;
    font-weight: bold;
  }
  
  .transaction-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .transaction-table .positive {
    color: green;
  }
  
  .transaction-table .negative {
    color: red;
  }`, "",{"version":3,"sources":["webpack://./src/components/Tables/table.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;EAC3B;;EAEA;;IAEE,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;IACzB,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,UAAU;EACZ","sourcesContent":[".transaction-table {\r\n    width: 100%;\r\n    border-collapse: collapse;\r\n  }\r\n  \r\n  .transaction-table th,\r\n  .transaction-table td {\r\n    border: 1px solid #ddd;\r\n    padding: 8px;\r\n    text-align: left;\r\n  }\r\n  \r\n  .transaction-table th {\r\n    background-color: #000000;\r\n    font-weight: bold;\r\n  }\r\n  \r\n  .transaction-table tr:nth-child(even) {\r\n    background-color: #f9f9f9;\r\n  }\r\n  \r\n  .transaction-table .positive {\r\n    color: green;\r\n  }\r\n  \r\n  .transaction-table .negative {\r\n    color: red;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
