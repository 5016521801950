export const manageVipSubs = [
  {
    vip_id: "BC123456789",
    member_name: "Sam",
    email: "abc@gmail.com",
    mobile_number: "912134567890",
    subs_expires: "25 - 5 - 2024",
    last_renew_date: "26 - 4- 2024",
  },
  {
    vip_id: "BC123456789",
    member_name: "Sam",
    email: "abc@gmail.com",
    mobile_number: "912134567890",
    subs_expires: "25 - 5 - 2024",
    last_renew_date: "26 - 4- 2024",
  },
  {
    vip_id: "BC123456789",
    member_name: "Sam",
    email: "abc@gmail.com",
    mobile_number: "912134567890",
    subs_expires: "25 - 5 - 2024",
    last_renew_date: "26 - 4- 2024",
  },
  {
    vip_id: "BC123456789",
    member_name: "Sam",
    email: "abc@gmail.com",
    mobile_number: "912134567890",
    subs_expires: "25 - 5 - 2024",
    last_renew_date: "26 - 4- 2024",
  },
  {
    vip_id: "BC123456789",
    member_name: "Sam",
    email: "abc@gmail.com",
    mobile_number: "912134567890",
    subs_expires: "25 - 5 - 2024",
    last_renew_date: "26 - 4- 2024",
  },
  {
    vip_id: "BC123456789",
    member_name: "Sam",
    email: "abc@gmail.com",
    mobile_number: "912134567890",
    subs_expires: "25 - 5 - 2024",
    last_renew_date: "26 - 4- 2024",
  },
  {
    vip_id: "BC123456789",
    member_name: "Sam",
    email: "abc@gmail.com",
    mobile_number: "912134567890",
    subs_expires: "25 - 5 - 2024",
    last_renew_date: "26 - 4- 2024",
  },
  {
    vip_id: "BC123456789",
    member_name: "Sam",
    email: "abc@gmail.com",
    mobile_number: "912134567890",
    subs_expires: "25 - 5 - 2024",
    last_renew_date: "26 - 4- 2024",
  },
  {
    vip_id: "BC123456789",
    member_name: "Sam",
    email: "abc@gmail.com",
    mobile_number: "912134567890",
    subs_expires: "25 - 5 - 2024",
    last_renew_date: "26 - 4- 2024",
  },
  {
    vip_id: "BC123456789",
    member_name: "Sam",
    email: "abc@gmail.com",
    mobile_number: "912134567890",
    subs_expires: "25 - 5 - 2024",
    last_renew_date: "26 - 4- 2024",
  },
];
