import React from "react";
import ModalLayout from "../../Layouts/ModalLayout";
import { X } from "lucide-react";
import { getUser } from "../../../services/auth";
import {
  deleteMember,
  deleteStateMember,
} from "../../../services/subRetailerServices";
import { toast } from "react-toastify";
import { useAppContext } from "../../../context/AppContext";

const DeleteWarrningModal = ({ selectedMember }) => {
  const { setModal, setRetailRefresh, userData } = useAppContext();

  const handleDeleteMember = async () => {
    try {
      setModal("pending");
      const user = await getUser();
      let res;
      if (userData && userData.role === "RETAILER") {
        res = await deleteMember(user, selectedMember._id);
      } else {
        res = await deleteStateMember(user, selectedMember._id);
      }
      
      if (res.status) {
        setRetailRefresh(true);
        setModal("delete");
        toast.success(res.message);
      }
    } catch (error) {
      setModal("delete-warrning");
      console.error(error);
    }
  };

  return (
    <>
      <ModalLayout maxWidth="max-w-fit">
        <div className="confirmation-card font-suse">
          <div className="card-header">
            <h2>Confirmation</h2>
            <X className="close-icon" onClick={() => setModal("")} />
          </div>
          <p className="warr-p">
            Are you sure you want to{" "}
            <span style={{ color: "red" }}>
              <b>Delete</b>
            </span>{" "}
            this Sub Retailer?
          </p>
          <div className="button-container">
            <button onClick={handleDeleteMember} className="confirm-button">
              Delete
            </button>
            <button className="cancel-button" onClick={() => setModal("")}>
              Cancel
            </button>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default DeleteWarrningModal;
