// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-layout-container {
  width: 100%;
  min-height: 100vh;
  height: 100%;
}

.main-child-container {
  width: calc(100% - 3rem);
  height: 100%;
  padding: 1.5rem;
}

.modal-container {
  width: calc(100% - 1rem);
  height: 100%;
  background-color: rgba(0, 0, 0, 0.184);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
  padding: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Layouts/layout.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,wBAAwB;EACxB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,wBAAwB;EACxB,YAAY;EACZ,sCAAsC;EACtC,eAAe;EACf,MAAM;EACN,OAAO;EACP,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".main-layout-container {\r\n  width: 100%;\r\n  min-height: 100vh;\r\n  height: 100%;\r\n}\r\n\r\n.main-child-container {\r\n  width: calc(100% - 3rem);\r\n  height: 100%;\r\n  padding: 1.5rem;\r\n}\r\n\r\n.modal-container {\r\n  width: calc(100% - 1rem);\r\n  height: 100%;\r\n  background-color: rgba(0, 0, 0, 0.184);\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  z-index: 1000;\r\n  overflow-y: auto;\r\n  padding: 0.5rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
