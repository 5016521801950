import { httpAxios } from "../../helper/httpHelper";

export async function fetchStateMembers(user) {
  try {
    const { data } = await httpAxios.get(`/retailer/state-user/list`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${user}`,
      },
    });

    return data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch subscribers"
    );
  }
}

export async function createStateMember(user, formData) {
  try {
    const { data } = await httpAxios.post(
      `/retailer/state-user/create`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: user,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function addAmountToStateMember(user, userId, amount) {
  try {
    const params = {
      userId: userId,
      amount: amount,
    };
    const { data } = await httpAxios.post(
      `/retailer/state-user/balance/add`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: user,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function deductAmountToStateMember(user, userId, amount) {
  try {
    const params = {
      userId: userId,
      amount: amount,
    };

    const { data } = await httpAxios.post(
      `/retailer/state-user/balance/deduct`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: user,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}