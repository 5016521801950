import React, { useState } from "react";
import ModalLayout from "../../Layouts/ModalLayout";
import { X } from "lucide-react";
import {
  transferAmount,
  transferAmountToRetailer,
} from "../../../services/dashboardServices";
import { toast } from "react-toastify";
import { useAppContext } from "../../../context/AppContext";
import PendingTxn from "./PendingTxn";
import SuccessTxs from "./SuccessTxs";
import { getUser } from "../../../services/auth";

const FundTransferModal = ({ searchedUser, setTransferModal }) => {
  const [amount, setAmount] = useState("");
  const [status, setStatus] = useState("");

  const { setModal, setFundRefresh, userData } = useAppContext();

  const handleFundTransfer = async () => {
    try {
      if (amount <= 0) {
        toast.error("Please enter an amount");
        return;
      }
      setStatus("Pending");
      const user = await getUser();
      let data;
      if (userData && userData.role === "STATE") {
        data = await transferAmountToRetailer(
          user,
          searchedUser._id,
          Number(amount)
        );
      } else {
        data = await transferAmount(user, searchedUser._id, Number(amount));
      }
      
      if (data.status) {
        toast.success(data.message);
        setStatus("Success");
        //Show Success modal for 2Sec
        setTimeout(() => {
          setTransferModal("");
          setModal("");
          setStatus("");
          setFundRefresh(true);
        }, 2000);
      } else {
        setStatus("");
        toast.error(data.message);
      }
    } catch (error) {
      setStatus("");
      console.log(error);
    }
  };

  return (
    <>
      <ModalLayout maxWidth="max-w-sm">
        {status === "Pending" && <PendingTxn />}
        {status === "Success" && <SuccessTxs message="Transfer Success" />}
        <div className="">
          <div className="modal-header">
            <div className="modal-header-body"></div>
            <div className="modal-header-body2">Transfer Fund</div>
            <div
              onClick={() => setTransferModal("")}
              className="modal-header-body"
            >
              <X style={{ cursor: "pointer" }} />
            </div>
          </div>
          <div className="p-4 flex flex-col gap-8">
            <div className="modal-header-body"></div>
            <div className="">
              <p className="">Amount</p>
              <input
                min={0}
                placeholder="Enter amount"
                type="number"
                title="amount"
                name="Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                onWheel={(e) => e.target.blur()}
                className="border h-10 rounded-xl w-full p-2 text-sm outline-none mt-1"
              />
            </div>
            <div className="modal-header-body"></div>
            <div className="w-full">
              <button
                disabled={amount <= 0}
                onClick={handleFundTransfer}
                type="button"
                className={`w-full text-sm h-10 rounded-xl text-white bg-custom-border  ${
                  amount <= 0
                    ? "opacity-40 cursor-not-allowed"
                    : "hover:opacity-85"
                }`}
              >
                Transfer Now
              </button>
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default FundTransferModal;
