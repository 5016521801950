import React, { useState } from "react";
import { useAppContext } from "../../context/AppContext";
import AddAmountModal from "../Modals/ManageRetailer/AddAmountModal";
import DeductAmountModal from "../Modals/ManageRetailer/DeductAmountModal";
import DeleteWarrningModal from "../Modals/ManageRetailer/DeleteWarrningModal";
import TransactionsModal from "../Modals/ManageRetailer/TransactionsModal";
import EditSubRetailerModal from "../Modals/ManageRetailer/EditSubRetailerModal";

const SubRetailserTable = ({ subRetailers, currentPage, limit }) => {
  const { modal, setModal } = useAppContext();
  const [selectedMember, setSelectedMember] = useState({});

  // console.log("subRetailers", subRetailers);

  return (
    <>
      {modal === "edit-retailer" && (
        <EditSubRetailerModal selectedMember={selectedMember} />
      )}
      {modal === "txns" && (
        <TransactionsModal
          setModal={setModal}
          selectedMember={selectedMember}
        />
      )}
      {modal === "delete-warrning" && (
        <DeleteWarrningModal selectedMember={selectedMember} />
      )}
      {modal === "deduct-amount" && (
        <DeductAmountModal selectedMember={selectedMember} />
      )}
      {modal === "add-amount" && (
        <AddAmountModal selectedMember={selectedMember} />
      )}
      <div className="main-table-container">
        <table className="data-table font-suse">
          <thead>
            <tr>
              <th>Sl. No.</th>
              <th>Name</th>
              <th>Email ID</th>
              <th>Mobile Number</th>
              <th>Country</th>
              <th>Pincode</th>
              {/* <th>KYC Status</th> */}
              <th>Currency</th>
              <th>Amount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {subRetailers?.map((row, id) => (
              <tr key={row._id}>
                <td>{id + 1 + (currentPage - 1) * limit}.</td>
                <td>{row.name}</td>
                <td>{row.email}</td>
                <td>{row.mobile}</td>
                <td>{row.country.country}</td>
                <td>{row.pincode ?? "-"}</td>
                {/* <td
                  className={`italic ${
                    row.kyc.status === "PENDING"
                      ? "text-yellow-500"
                      : row.kyc.status === "APPROVED"
                      ? "text-emerald-500"
                      : "text-red-500"
                  }`}
                >
                  {row.kyc.status}
                </td> */}
                <td>{row.country.currencyCode}</td>
                <td className="add-remove-retailer">
                  <p
                    style={{
                      color: row.fundBalance === 0 ? "red" : "",
                      fontSize: "14px",
                    }}
                  >
                    {row.fundBalance.toFixed(2)}
                  </p>
                  <div className="add-remove-btn">
                    <button
                      title="Add Funds"
                      onClick={() => {
                        setModal("add-warrning");
                        setSelectedMember(row);
                      }}
                      className="plus-btn"
                    >
                      <img
                        src="/images/plus.png"
                        alt="Add"
                        loading="lazy"
                        className="plus"
                      />
                    </button>
                    <button
                      title="Deduct Funds"
                      onClick={() => {
                        setModal("deduct-warrning");
                        setSelectedMember(row);
                      }}
                      className="minus-btn"
                      disabled={row.fundBalance === 0}
                      style={{
                        backgroundColor:
                          row.fundBalance === 0 ? "#8B8B8B" : "#1D5E9A",
                        opacity: row.fundBalance === 0 ? 0.5 : 1,
                        cursor:
                          row.fundBalance === 0 ? "not-allowed" : "pointer",
                      }}
                    >
                      <img
                        src="/images/minus.png"
                        alt="Remove"
                        loading="lazy"
                        className="minus"
                      />
                    </button>
                  </div>
                </td>
                <td>
                  <button
                    type="button"
                    onClick={() => {
                      setModal("edit-retailer");
                      setSelectedMember(row);
                    }}
                    title="Edit"
                    className="action-btn edit"
                  >
                    <img
                      src="/images/edit_note.png"
                      alt="Edit"
                      loading="lazy"
                    />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setModal("delete-warrning");
                      setSelectedMember(row);
                    }}
                    title="Delete"
                    className="action-btn delete"
                  >
                    <img src="/images/delete.png" alt="Edit" loading="lazy" />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setModal("txns");
                      setSelectedMember(row);
                    }}
                    title="Transactions"
                    className="action-btn download"
                  >
                    <img src="/images/clock.png" alt="Edit" loading="lazy" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SubRetailserTable;
