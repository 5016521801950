import React, { useCallback, useEffect, useState } from "react";
import ReceiptSubmitModal from "../Modals/ReceiptUpload/ReceiptSubmitModal";
import SubmitSucceedModal from "../Modals/ReceiptUpload/SubmitSucceedModal";
import { formatDate } from "../../utils/formatTime";
import { useAppContext } from "../../context/AppContext";
import Pagination from "../Pagination";
import { getPendingRecharges } from "../../services/dashboardServices";
import { getUser } from "../../services/auth";
import { Check, File } from "lucide-react";
import VerifyTxnModal from "../Modals/CryptoRecharges/VerifyTxnModal";

const ReceiptPending = () => {
  const [singleReceipt, setSingleReceipt] = useState({});
  const [selectedReceipts, setSelectedReceipts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [userID, setUserID] = useState(null);
  const [symbol, setSymbol] = useState("");

  // console.log("selectedReceipts", selectedReceipts?.docs?.length !== 0 && selectedReceipts?.docs?.length !== undefined);

  const {
    modal,
    setModal,
    receiptRefresh,
    setReceiptRefresh,
    country,
    userProfile,
  } = useAppContext();

  const status = "PENDING";
  const pageLimit = 10;

  // Fetch Pending Receipts
  const handleGetPendingReceipt = useCallback(async () => {
    try {
      const user = await getUser();
      const data = await getPendingRecharges(
        user,
        status,
        currentPage,
        pageLimit
      );
      if (data.status) {
        setReceiptRefresh(false);
        setSelectedReceipts(data.data);
        setTotalPages(data.data.totalPages);
      }
    } catch (error) {
      console.log(error);
    }
  }, [status, currentPage, setReceiptRefresh]);

  useEffect(() => {
    handleGetPendingReceipt();
  }, [receiptRefresh, currentPage]);

  const handleUploadReceipt = (receipt) => {
    if (receipt) {
      setSingleReceipt(receipt);
      setModal("submit");
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleCryptoVerify = (id, symbol) => {
    setUserID(id);
    setSymbol(symbol);
    setModal("crypto_verify");
  };

  return (
    <>
      {modal === "submit" && (
        <ReceiptSubmitModal setModal={setModal} singleReceipt={singleReceipt} />
      )}
      {modal === "uploaded" && <SubmitSucceedModal setModal={setModal} />}

      {modal === "crypto_verify" && (
        <VerifyTxnModal setModal={setModal} userID={userID} symbol={symbol} />
      )}
      <div className="">
        <div className="w-full h-full min-h-[438px] p-4 border border-custom-border rounded-xl shadow-md">
          <div className="w-full flex flex-col sm:flex-row justify-between items-center pb-4">
            <h1 className="font-semibold text-center text-sm">
              Add Funds Receipt Upload Pending
            </h1>
            {selectedReceipts?.docs?.length !== 0 &&
              selectedReceipts?.docs?.length !== undefined && (
                <h5 className="font-semibold text-sm">
                  Showing {selectedReceipts?.page} of{" "}
                  {selectedReceipts?.totalPages}
                </h5>
              )}
          </div>
          {selectedReceipts?.docs?.length !== 0 &&
          selectedReceipts?.docs?.length !== undefined ? (
            <div className="max-h-[340px] h-full overflow-x-auto overflow-y-scroll rounded-xl border border-custom-border scrollbar-hide">
              <table className="border-b border-custom-border sticky top-0 w-full">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-4 text-left text-xs bg-gradient-to-t from-custom-border to-bg-color text-white font-semibold uppercase tracking-wider whitespace-nowrap border-r border-custom-border"
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-4 text-left text-xs bg-gradient-to-t from-custom-border to-bg-color text-white font-semibold uppercase tracking-wider whitespace-nowrap border-r border-custom-border"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-4 text-left text-xs bg-gradient-to-t from-custom-border to-bg-color text-white font-semibold uppercase tracking-wider whitespace-nowrap border-r border-custom-border"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-4 text-left text-xs bg-gradient-to-t from-custom-border to-bg-color text-white font-semibold uppercase tracking-wider whitespace-nowrap border-r border-custom-border"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {selectedReceipts.docs?.map((receipt, id) => (
                    <tr
                      key={id}
                      className={` ${
                        id % 2 === 0 ? "bg-white" : "bg-bg-color/10"
                      } 
                    ${
                      id !== selectedReceipts?.length - 1
                        ? "border-b border-custom-border"
                        : ""
                    }
                    `}
                    >
                      <td className="p-2 whitespace-nowrap text-sm">
                        <span className="flex justify-start items-center">
                          <img
                            src={country?.currencySymbol}
                            alt="symbol"
                            width={12}
                            height={12}
                            title={country?.currencyCode}
                            style={{ marginRight: "8px" }}
                          />
                          {receipt.fundAmount}
                        </span>
                      </td>
                      <td className="p-2 whitespace-nowrap text-sm">
                        {formatDate(receipt.createdAt)}
                      </td>
                      <td className="p-2 whitespace-nowrap text-sm">
                        {receipt.paymentMethod}
                      </td>
                      <td className="p-2 whitespace-nowrap text-sm">
                        {receipt.status === "PENDING" ? (
                          <>
                            {receipt.paymentMethod === "CRYPTO" ? (
                              <button
                                onClick={() =>
                                  handleCryptoVerify(
                                    receipt._id,
                                    receipt.cryptoPlatformSymbol
                                  )
                                }
                                className="bg-custom-border text-white p-2 px-4 text-xs rounded-lg hover:opacity-85"
                              >
                                Receipt Upload
                              </button>
                            ) : (
                              <button
                                onClick={() => handleUploadReceipt(receipt)}
                                className="bg-custom-border text-white p-2 px-4 text-xs rounded-lg hover:opacity-85"
                              >
                                Receipt Upload
                              </button>
                            )}
                          </>
                        ) : (
                          <span title="Verified">
                            <Check style={{ color: "green" }} />
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="w-full h-72 flex flex-col justify-center items-center">
              <File />
              <p style={{ paddingTop: "5px", fontSize: "14px" }}>
                Pending transactions not found.
              </p>
            </div>
          )}
          {selectedReceipts?.docs?.length > 0 &&
          selectedReceipts?.totalPages >= 2 ? (
            <div className="pagination-container">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ReceiptPending;
