import React from "react";
import MainLayout from "../components/Layouts/MainLayout";
import { ArrowLeft, Construction } from "lucide-react";
import { Link } from "react-router-dom";

const CryptoSoundBoxScreen = () => {
  return (
    <>
      <MainLayout>
        <div className="w-full min-h-screen h-full flex flex-col justify-center items-center text-center">
          <Construction className="w-16 h-16 text-gray-500 mb-4" />
          <h1 className="text-2xl font-semibold text-gray-700">Coming Soon</h1>
          <p className="mt-2 text-gray-500">
            We are working hard to bring this feature to you. Stay tuned!
          </p>

          <Link
            to="/dashboard"
            className="mt-4 px-4 py-2 bg-black text-white rounded-lg hover:bg-black/85 flex justify-center items-center gap-2 text-sm"
          >
            <ArrowLeft className="w-4 h-4" />
            Go To Dashboard
          </Link>
        </div>
      </MainLayout>
    </>
  );
};

export default CryptoSoundBoxScreen;
