export const restoreArr = [
  {
    profile: "/images/vip.png",
    member_name: "Sam",
    email: "abc@gmail.com",
    mobile_number: "1234567890",
    member_id: "VT - 123456789",
    status: "Deleted",
    balance: "222.02",
  },
  {
    profile: "/images/vip.png",
    member_name: "Sam",
    email: "abc@gmail.com",
    mobile_number: "1234567890",
    member_id: "VT - 123456789",
    status: "Restored",
    balance: "220.00",
  },
  {
    profile: "/images/vip.png",
    member_name: "Sam",
    email: "abc@gmail.com",
    mobile_number: "1234567890",
    member_id: "VT - 123456789",
    status: "Deleted",
    balance: "120.243",
  },
];
