import React, { useEffect, useState } from "react";
import MainLayout from "../../components/Layouts/MainLayout";
import "./retailer.css";
import { CircleChevronLeft, FileText, Search } from "lucide-react";
import { Link } from "react-router-dom";
import SubRetailserTable from "../../components/Tables/SubRetailserTable";
import Pagination from "../../components/Pagination";
import AddSubRetailerModal from "../../components/Modals/ManageRetailer/AddSubRetailerModal";
import AddWarrningModal from "../../components/Modals/ManageRetailer/AddWarrningModal";
import { useAppContext } from "../../context/AppContext";
import DeductWarrningModal from "../../components/Modals/ManageRetailer/DeductWarrningModal";
import { getUser } from "../../services/auth";
import { subRetailerMembersList } from "../../services/subRetailerServices";
import SuccessTxs from "../../components/Modals/TransferFunds/SuccessTxs";
import PendingTxn from "../../components/Modals/TransferFunds/PendingTxn";
import DeleteModal from "../../components/Modals/ManageRetailer/DeleteModal";
import SuccessDetailsModal from "../../components/Modals/ManageRetailer/SuccessDetailsModal";

const ManageSubRetailserScreen = () => {
  const { modal, setModal, retailRefresh, setRetailRefresh, userProfile } =
    useAppContext();

  const [subRetailers, setSubRetailers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [retailerDetails, setRetailerDetails] = useState({});

  useEffect(() => {
    const handleRetailerList = async () => {
      try {
        const user = await getUser();
        const data = await subRetailerMembersList(
          user,
          currentPage,
          limit,
          search
        );
        if (data.status) {
          setRetailRefresh(false);
          setSubRetailers(data.data.docs);
          setTotalPages(data.data.totalPages);
        }
      } catch (error) {
        console.error(error);
      }
    };

    handleRetailerList();
  }, [currentPage, limit, search, retailRefresh]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // console.log("subRetailers", subRetailers);

  return (
    <>
      {modal === "add-retailer" && (
        <AddSubRetailerModal setRetailerDetails={setRetailerDetails} />
      )}
      {modal === "success-details" && (
        <SuccessDetailsModal retailerDetails={retailerDetails} />
      )}
      {modal === "add-warrning" && <AddWarrningModal setModal={setModal} />}
      {modal === "deduct-warrning" && (
        <DeductWarrningModal setModal={setModal} />
      )}
      {modal === "success" && <SuccessTxs message="Added Successfully" />}
      {modal === "delete" && <DeleteModal message="Deleted Successfully" />}
      {modal === "pending" && <PendingTxn />}
      <MainLayout>
        <div className="main-sub-retailer-container">
          <div>
            <Link to="/dashboard" className="back-btn">
              <CircleChevronLeft />
              Manage Sub Retailer
            </Link>
          </div>

          <div className="sub-container">
            <div className="search-container">
              <Search className="search" />
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="search"
                id="search"
                placeholder="Search Name/Mobile No./ Email ID"
              />
            </div>
            <div className="btn-container">
              <button onClick={() => setModal("add-retailer")}>
                Add Sub Retailer
              </button>
            </div>
          </div>

          {subRetailers.length > 0 ? (
            <>
              <div>
                <SubRetailserTable
                  subRetailers={subRetailers}
                  currentPage={currentPage}
                  limit={limit}
                />
              </div>
              <div>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </>
          ) : (
            <div
              style={{
                padding: "80px 0 80px 0",
                textAlign: "center",
                fontWeight: 600,
              }}
            >
              <div className=" min-h-56 w-full h-full flex gap-1 flex-col justify-center items-center">
                <div>
                  <FileText />
                </div>
                <p>No Transactions Found.</p>
              </div>
            </div>
          )}
        </div>
      </MainLayout>
    </>
  );
};

export default ManageSubRetailserScreen;
