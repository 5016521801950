import React, { useEffect, useState } from "react";
import { getUser } from "../../services/auth";
import { createVipId, updateVipId } from "../../services/generateVipServices";
import { toast } from "react-toastify";
import { Trash2 } from "lucide-react";
import { useAppContext } from "../../context/AppContext";
import DeleteVipIdModal from "../Modals/DeleteVipIdModal";

const EditVipMemberForm = ({
  triggerFetch,
  selectedId,
  setEdit,
  setSelectedId,
}) => {
  const [formData, setFormData] = useState({
    userId: "",
    vipId: "",
    name: "",
    country: "",
    email: "",
    mobile: "",
    zone: "", /// [PRIVATE,PUBLIC]
    password: "",
    role: "", /// [USER,SUPER]
  });

  const { modal, setModal } = useAppContext();

  // console.log("selectedId", selectedId);

  // Update form fields whenever `selectedId` changes
  useEffect(() => {
    if (selectedId) {
      setFormData({
        userId: selectedId.id || "",
        vipId: selectedId.referralCode || "",
        name: selectedId.name || "",
        country: selectedId.country || "",
        email: selectedId.email || "",
        mobile: selectedId.mobile || "",
        zone: selectedId.zone || "",
        password: "", // password is typically reset manually
        role: selectedId.role || "",
      });
    }
  }, [selectedId]); // This ensures form updates when a new VIP is selected

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      // Reset referralCode and partnerCode based on reference type
      if (name === "reference") {
        if (value === "SELF") {
          return {
            ...prev,
            referralCode: "",
            partnerCode: "",
            [name]: value,
          };
        } else if (value === "INTRODUCER") {
          return {
            ...prev,
            partnerCode: "",
            [name]: value,
          };
        } else if (value === "PARTNER") {
          return {
            ...prev,
            referralCode: "",
            [name]: value,
          };
        }
      }

      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmitEditForm = async (e) => {
    e.preventDefault();

    try {
      const user = await getUser();
      const formDataToSubmit = { ...formData };

      if (!formDataToSubmit.password) {
        formDataToSubmit.password = undefined;
      }

      const res = await updateVipId(user, formDataToSubmit);

      if (res.status) {
        toast.success(res.message);
        setEdit("");
        setSelectedId(null);
        triggerFetch();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while saving VIP Member details.");
    }
  };
  return (
    <>
      {modal === "delete-vip" && selectedId && (
        <DeleteVipIdModal
          triggerRefetch={triggerFetch}
          userId={selectedId?.id}
          setEdit={setEdit}
          setSelectedId={setSelectedId}
        />
      )}
      <div className="h-full">
        <form className="h-full" onSubmit={handleSubmitEditForm}>
          <div className="flex flex-col sm:flex-row justify-between items-start gap-4 w-full h-full">
            <div className="flex flex-col gap-6 w-full">
              <span className="space-y-1">
                <h1 className="text-sm">VIP ID</h1>
                <input
                  name="vipId"
                  value={formData.vipId}
                  onChange={handleChange}
                  placeholder="Enter VIP ID"
                  type="text"
                  required
                  className="border p-2 h-10 rounded-xl w-full outline-none text-sm"
                />
              </span>
              <span className="space-y-1">
                <h1 className="text-sm">Member Name</h1>
                <input
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter name"
                  type="text"
                  required
                  className="border p-2 h-10 rounded-xl w-full outline-none text-sm"
                />
              </span>
              <span className="space-y-1">
                <h1 className="text-sm">Country</h1>
                <select
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  className="w-full border rounded-xl h-10 text-sm p-2 outline-none"
                >
                  <option value="">Select your country</option>
                  <option value="India">India</option>
                  <option value="Bangladesh">Bangladesh</option>
                </select>
              </span>
              <span className="space-y-1">
                <h1 className="text-sm">Email ID</h1>
                <input
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter Email ID"
                  type="email"
                  required
                  className="border p-2 h-10 rounded-xl w-full outline-none text-sm"
                />
              </span>
              {/* {formData.reference === "INTRODUCER" && (
                <span className="space-y-1">
                  <h1 className="text-sm">Referral Code</h1>
                  <input
                    name="referralCode"
                    value={formData.referralCode}
                    onChange={handleChange}
                    placeholder="Enter Referral Code"
                    type="text"
                    required
                    className="border p-2 h-10 rounded-xl w-full outline-none text-sm"
                  />
                </span>
              )}
              {formData.reference === "PARTNER" && (
                <span className="space-y-1">
                  <h1 className="text-sm">Partner Code</h1>
                  <input
                    name="partnerCode"
                    value={formData.partnerCode}
                    onChange={handleChange}
                    placeholder="Enter Partner Code"
                    type="text"
                    required
                    className="border p-2 h-10 rounded-xl w-full outline-none text-sm"
                  />
                </span>
              )} */}
              <span className="space-y-1">
                <h1 className="text-sm">Role</h1>
                <select
                  required
                  name="role"
                  value={formData.role}
                  onChange={handleChange}
                  className="w-full border rounded-xl h-10 text-sm p-2 outline-none"
                >
                  <option value="">Select Role</option>
                  <option value="USER">USER</option>
                  <option value="SUPER">SUPER</option>
                </select>
              </span>
            </div>
            <div className="flex flex-col gap-6 w-full">
              <span className="space-y-1">
                <h1 className="text-sm">Mobile</h1>
                <input
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  placeholder="Enter mobile number"
                  type="number"
                  required
                  className="border p-2 h-10 rounded-xl w-full outline-none text-sm"
                />
              </span>
              <span className="space-y-1">
                <h1 className="text-sm">Zone Selection</h1>
                <select
                  name="zone"
                  value={formData.zone}
                  onChange={handleChange}
                  className="w-full border rounded-xl h-10 text-sm p-2 outline-none"
                >
                  <option value="">Select Zone</option>
                  <option value="PRIVATE">Private</option>
                  <option value="PUBLIC">Public</option>
                </select>
              </span>
              <span className="space-y-1">
                <h1 className="text-sm">Password</h1>
                <input
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Enter password"
                  type="password"
                  className="border p-2 h-10 rounded-xl w-full outline-none text-sm"
                />
              </span>
              <span className="space-y-1">
                <h1 className="text-sm">Reference</h1>
                <select
                  disabled
                  name="reference"
                  value={formData.reference}
                  onChange={handleChange}
                  className="w-full border rounded-xl h-10 text-sm p-2 outline-none cursor-not-allowed"
                >
                  <option value="SELF">Self</option>
                  <option value="INTRODUCER">Introducer</option>
                  <option value="PARTNER">Partner</option>
                </select>
              </span>
            </div>
          </div>

          <div className="h-full mt-16 xl:mt-52 flex gap-4 justify-center items-center">
            <button
              type="submit"
              className="bg-custom-border w-44 p-2 rounded-xl text-white hover:opacity-80"
            >
              Save
            </button>
            <button
              onClick={() => setModal("delete-vip")}
              type="button"
              className="bg-red-200 w-44 p-2 rounded-xl flex justify-center items-center gap-2 text-red-700 hover:opacity-80"
            >
              <Trash2 className="w-5 h-5" /> Delete
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditVipMemberForm;
