import React, { memo, useEffect, useState } from "react";
import ModalLayout from "../../Layouts/ModalLayout";
import { ChevronDown, Currency, X } from "lucide-react";
import CurrencyDropdown from "../../CurrencyDropdown";
import { getUser } from "../../../services/auth";
import {
  createRechargeReq,
  getLiveUSDTPrice,
  getNetworkLists,
} from "../../../services/dashboardServices";
import { useAppContext } from "../../../context/AppContext";
import SkullLoader from "../../SkullLoader";
import { toast } from "react-toastify";

const CryptoTxnModal = ({ data }) => {
  const {
    amount,
    setAmount,
    selectedCurrency,
    convertAmount,
    setConvertAmount,
    network,
    setNetwork,
    selectedNetwork,
    setSelectedNetwork,
    setCryptoRecDetails,
  } = data;
  const [select, setSelect] = useState(false);
  const [usdtPrice, setUsdtPrice] = useState(null);

  const { setModal, setReceiptRefresh, country } = useAppContext();

  // console.log(selectedNetwork);

  const getNetworks = async () => {
    try {
      const user = await getUser();
      const data = await getNetworkLists(user);
      if (data.status) {
        setNetwork(data.data);
        setSelectedNetwork(data.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchUsdtPrice = async () => {
      try {
        const user = await getUser();
        const data = await getLiveUSDTPrice(user);

        if (data.status) {
          setUsdtPrice(data.data.price);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchUsdtPrice();
    getNetworks();
  }, []);

  useEffect(() => {
    if (amount && usdtPrice) {
      setConvertAmount((amount / usdtPrice).toFixed(2));
    } else {
      setConvertAmount("");
    }
  }, [amount, usdtPrice]);

  const handleRechargeReq = async () => {
    try {
      setModal("pending");
      const user = await getUser();
      const data = await createRechargeReq(
        user,
        Number(amount),
        Number(convertAmount),
        selectedNetwork
      );

      if (data.status) {
        setReceiptRefresh(true);
        setModal("qr-modal");
        setCryptoRecDetails(data.data);
      } else {
        toast.error(data.message);
        setModal("crypto-recharge");
      }
    } catch (error) {
      console.error(error);
      setModal("crypto-recharge");
    } finally {
      if (setModal === "pending") {
        setModal("crypto-recharge");
      }
    }
  };

  return (
    <>
      <ModalLayout maxWidth="max-w-96">
        <div className="bg-white max-w-4xl w-full h-full">
          <div className="modal-header">
            <div className="modal-header-body"></div>
            <div className="modal-header-body2">Crypto Recharge</div>
            <div onClick={() => setModal("")} className="modal-header-body">
              <X style={{ cursor: "pointer" }} />
            </div>
          </div>

          <div className="p-4 w-full h-full">
            <div className="">
              <div>
                <h1 className="mb-1">Amount</h1>
                <div className="flex items-center gap-1.5 px-2 border h-10 rounded-lg ">
                  <div>
                    <img
                      src={country?.currencySymbol}
                      alt="symbol"
                      width={12}
                      height={12}
                      title={country?.currencyCode}
                    />
                  </div>
                  <input
                    onChange={(e) => setAmount(e.target.value)}
                    value={amount || ""}
                    title="Amount"
                    name="amount"
                    type="number"
                    placeholder="Enter Amount"
                    className="w-full outline-none bg-transparent"
                  />
                </div>
              </div>
              <div className="mt-2">
                <h1 className="mb-1">You Pay</h1>
                <div className="flex items-center gap-1 px-2 border h-10 rounded-lg ">
                  <input
                    readOnly
                    value={convertAmount || ""}
                    title="Pay Amount"
                    name="pay"
                    type="number"
                    placeholder="00.00"
                    className="w-full outline-none bg-transparent"
                  />
                  {/* <h5>{selectedCurrency}</h5> */} USDT
                </div>
              </div>
              <div className="mt-2">
                <h1 className="mb-1">Select Network</h1>
                <div className="">
                  {selectedNetwork?.logo ? (
                    <div
                      onClick={() => setSelect(!select)}
                       className="flex items-center justify-between gap-1 px-1 border h-10 rounded-lg cursor-pointer "
                    >
                      <div className="flex items-center gap-2">
                        <img
                          src={selectedNetwork?.logo}
                          alt="logo"
                          width={20}
                          height={20}
                          loading="lazy"
                        />
                        <h5>{selectedNetwork.name}</h5>
                      </div>
                      <ChevronDown style={{ width: "16px", height: "16px" }} />
                    </div>
                  ) : (
                    <SkullLoader />
                  )}
                </div>

                <div>
                  {select && (
                    <CurrencyDropdown
                      network={network}
                      setSelect={setSelect}
                      setNetwork={setNetwork}
                      selectedNetwork={selectedNetwork}
                      setSelectedNetwork={setSelectedNetwork}
                    />
                  )}
                </div>
              </div>
            </div>
            <div style={{ marginTop: "45px" }}>
              <button
                onClick={handleRechargeReq}
                className="bg-bg-color hover:bg-opacity-85 w-full h-10 rounded-xl text-white "
                disabled={
                  !amount ||
                  amount <= 0 ||
                  !convertAmount ||
                  convertAmount <= 0 ||
                  !selectedNetwork?.name
                }
                style={{
                  opacity:
                    !amount ||
                    amount <= 0 ||
                    !convertAmount ||
                    convertAmount <= 0 ||
                    !selectedNetwork?.name
                      ? 0.5
                      : 1,
                  cursor:
                    !amount ||
                    amount <= 0 ||
                    !convertAmount ||
                    convertAmount <= 0 ||
                    !selectedNetwork?.name
                      ? "not-allowed"
                      : "pointer",
                }}
              >
                Recharge Request
              </button>
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default memo(CryptoTxnModal);
