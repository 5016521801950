import React from "react";
import ModalLayout from "../Layouts/ModalLayout";
import { X } from "lucide-react";
import { useNavigate } from "react-router-dom";

const LeaveModal = ({ setBack }) => {
  const navigate = useNavigate();
  return (
    <ModalLayout maxWidth="max-w-sm">
      <div className="p-4 z-50">
        <div className="flex justify-end">
          <button onClick={() => setBack(false)} className="hover:text-red-500">
            <X />
          </button>
        </div>
        <div className=" text-center py-14">
          <p>Are you sure you want to leave this page?</p>
        </div>
        <div className="flex justify-between w-full h-10 gap-2">
          <button onClick={() => navigate("/")} className="rounded-xl bg-red-500 w-full text-white text-sm">
            Yes
          </button>
          <button onClick={() => setBack(false)} className="rounded-xl bg-emerald-600 w-full text-white text-sm">
            No
          </button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default LeaveModal;
