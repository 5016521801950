import React from "react";
import ModalLayout from "../Layouts/ModalLayout";
import { X } from "lucide-react";
import { useAppContext } from "../../context/AppContext";
import { useMutation } from "react-query";
import { getUser } from "../../services/auth";
import { toast } from "react-toastify";
import { payCheckout } from "../../services/statementServices";

const StatementCheckoutModal = ({
  setNewModal,
  checkoutData,
  selectedUser,
  otp,
  setOtp,
  setStatement,
}) => {
  // console.log("checkout ID", checkoutData);
  // console.log("selectedUser ID", selectedUser);
  // console.log("otp", otp);

  const payMutation = useMutation(
    async () => {
      const user = await getUser();
      return await payCheckout(user, checkoutData?._id, selectedUser?.id, otp);
    },
    {
      onSuccess: (data) => {
        if (data.status) {
          setNewModal("download");
          setStatement(data);
        } else {
          setNewModal("otp");
          setOtp("");
          toast.error(data.message || "Error downloading");
        }
      },
      onError: (error) => {
        console.error(error);
        toast.error("An error occurred");
      },
    }
  );

  return (
    <>
      <ModalLayout maxWidth="max-w-md">
        <div className="">
          <div className="flex justify-between items-center p-4 border-b">
            <h1 className="text-lg font-semibold">Checkout</h1>
            <button
              onClick={() => setNewModal("")}
              className="hover:bg-gray-200 hover:text-red-600 p-1 rounded-full"
            >
              <X />
            </button>
          </div>

          <div className="p-4">
            <div className="flex flex-col justify-center items-center ">
              <div className="w-24 h-24 p-2 shadow-lg rounded-full">
                <img
                  src="/images/ex.png"
                  loading="lazy"
                  className="w-full h-full"
                />
              </div>
              {/* <div className="mt-4 text-center">
                <p className="font-semibold text-lg">
                  {(selectedUser?.stockBalance)?.toFixed(2)}
                </p>
                <p className="text-sm">Current Balance</p>
              </div> */}
            </div>

            <div className="p-2 border border-blue-500 rounded-xl mt-8 space-y-2 text-sm">
              <span className="flex justify-between items-center">
                <p>Restore Amount(₹)</p>
                <p>{(checkoutData?.price).toLocaleString()}</p>
              </span>
              <span className="flex justify-between items-center">
                <p>Charges %</p>
                <p>{checkoutData?.taxPercent}</p>
              </span>
              {/* <span className="flex justify-between items-center">
                <p>Discount %</p>
                <p>{checkoutData?.discountPercent}</p>
              </span> */}
              <span className="font-semibold flex justify-between items-center">
                <p>Total Amount</p>
                <p>
                  {(
                    checkoutData?.price + checkoutData?.taxAmount
                  ).toLocaleString()}{" "}
                </p>
              </span>
            </div>
            <p className="text-xs mt-2">
              *Amount will be deducted from your wallet.
            </p>

            <div className="flex justify-center items-center gap-4 mt-12 mb-2 text-sm">
              <button
                onClick={() => payMutation.mutate()}
                disabled={payMutation.isLoading}
                className={`max-w-44 w-full ${
                  payMutation.isLoading
                    ? "bg-gray-400"
                    : "bg-custom-border hover:bg-opacity-85"
                } p-2 px-4 rounded-xl text-white flex justify-center items-center gap-2`}
              >
                {payMutation.isLoading ? "Loading..." : "Pay"}
              </button>
              <button
                onClick={() => setNewModal("")}
                className="w-44 h-10 border border-custom-border rounded-xl hover:bg-black/5"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default StatementCheckoutModal;
