import React, { useEffect, useState } from "react";
import "./auth.css";
import AuthLayout from "../../components/Layouts/AuthLayout";
import { Eye, EyeOff, LockKeyhole, Mail } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { login } from "../../services/userServices";
import { toast } from "react-toastify";

const LoginScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassowrd, setShowPassowrd] = useState(false);

  const navigate = useNavigate();

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    try {
      if (!email && !password) {
        toast.error("Please fill all the required fields");
        return;
      }
      const data = await login(email, password);
      if (data.status) {
        console.log("data", data);
        window.location.href = `/otp-verification?email=${email}`;
        navigate("/otp-verification");
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const user = localStorage.getItem("user");

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  return (
    <>
      <AuthLayout>

        <div className=" w-full flex justify-center items-center">
          <div className="max-w-md w-full">
            <div className="mt-4">
              <h1 className="text-2xl sm:text-4xl font-semibold text-center">
                Retailer Web Login
              </h1>
            </div>
            <div className="w-full h-full my-6 p-4">
              <form onSubmit={handleSubmitForm}>
                <div className="border border-black flex gap-2 w-full h-11 rounded-lg p-2">
                  <Mail className="w-6 h-6 text-neutral-600" />
                  <input
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    className="w-full outline-none"
                    id="email"
                    placeholder="Email ID"
                  />
                </div>
                <div>
                  <p className="text-xs mt-1.5">
                    OTP will be sent to your Registered Email Address
                  </p>
                </div>
                <div className="border border-black flex gap-2 w-full h-11 rounded-lg p-2 mt-6">
                  <LockKeyhole className="w-6 h-6 text-neutral-600" />
                  <input
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type={`${showPassowrd ? "text" : "password"}`}
                    className="w-full outline-none"
                    id="password"
                    placeholder="Password"
                  />
                  <button
                    style={{ backgroundColor: "transparent" }}
                    type="button"
                    onClick={() => setShowPassowrd(!showPassowrd)}
                  >
                    {showPassowrd ? <Eye className="w-5 h-5 text-neutral-600"/> : <EyeOff className="w-5 h-5 text-neutral-600"/>}
                  </button>
                </div>
                <div style={{ marginTop: "6rem" }} className="w-full h-full">
                  <button
                    type="submit"
                    className="w-full h-12 bg-custom-border hover:opacity-85 rounded-xl text-white font-semibold text-lg"
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
};

export default LoginScreen;
