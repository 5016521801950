import { httpAxios } from "../helper/httpHelper";
import { saveAs } from "file-saver";

export async function searchUser(user, searchQuery) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/user/get?search=${searchQuery}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response?.data || { message: "An error occurred" };
  }
}

export async function fetchUsers(user, page, limit, searchQuery) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/user/list?page=${page}&limit=${limit}&search=${searchQuery}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function requestOtp(user, userId) {
  try {
    const { data } = await httpAxios.post(
      `/retailer/statement/download/request/otp`,
      {
        userId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function statementCheckout(user) {
  try {
    const { data } = await httpAxios.post(
      `/retailer/statement/download/pay/checkout`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function payCheckout(user, checkoutId, userId, otp) {
  try {
    const { data } = await httpAxios.post(
      `/retailer/statement/download/pay`,
      { checkoutId, userId, otp },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function downloadPdf(user, pdfUrl) {
  try {
    const response = await httpAxios.get(pdfUrl, {
      headers: {
        Authorization: `${user}`,
      },
      responseType: "blob", 
    });

    const fileName = pdfUrl.split('/').pop(); 
    saveAs(response.data, fileName);

    return { status: true };
  } catch (error) {
    console.error("Error downloading PDF:", error);
    return { status: false, message: error.response?.data?.message || "Failed to download the PDF." };
  }
}



