import React from "react";
import "./table.css";
import { formatDateMonthYear } from "../../utils/formatTime";

const SubRetailerTxnTable = ({ transactions }) => {
  return (
    <>
      <table className="transaction-table font-suse">
        <thead>
          <tr>
            <th>Sl. No.</th>
            <th>Txn ID</th>
            <th>Method</th>
            <th>Amount (₹)</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((txn, id) => {
            const isDeduct = txn.type === "SUB-RETAILER-FUND-DEDUCT";
            const amount = isDeduct ? -txn.price : txn.price;

            return (
              <tr key={id}>
                <td>{id + 1}.</td>
                <td>{txn.transactionId}</td>
                <td>{isDeduct ? "Deduct" : "Add"}</td>
                <td className={amount > 0 ? "positive" : "negative"}>
                  {amount > 0 ? "+" : ""}
                  {amount}
                </td>
                <td>{formatDateMonthYear(txn.createdAt)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default SubRetailerTxnTable;
