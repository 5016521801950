import React, { useState } from "react";
import { getUser } from "../../services/auth";
import { createVipId } from "../../services/generateVipServices";
import { toast } from "react-toastify";

const AddVipMemberForm = ({ triggerFetch }) => {
  const [formData, setFormData] = useState({
    vipId: "",
    name: "",
    country: "",
    email: "",
    mobile: "",
    zone: "",
    password: "",
    reference: "SELF", // [SELF,INTRODUCER,PARTNER]
    referralCode: "",
    partnerCode: "",
  });

  // console.log("formData", formData);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      // Reset referralCode and partnerCode when switching to SELF
      if (name === "reference") {
        if (value === "SELF") {
          return {
            ...prev,
            referralCode: "",
            partnerCode: "",
            [name]: value,
          };
        } else if (value === "INTRODUCER") {
          return {
            ...prev,
            partnerCode: "", // Clear partnerCode when selecting INTRODUCER
            [name]: value,
          };
        } else if (value === "PARTNER") {
          return {
            ...prev,
            referralCode: "", // Clear referralCode when selecting PARTNER
            [name]: value,
          };
        }
      }

      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSubmit = { ...formData };

    if (!formDataToSubmit.referralCode) {
      formDataToSubmit.referralCode = undefined;
    }

    if (!formDataToSubmit.partnerCode) {
      formDataToSubmit.partnerCode = undefined;
    }

    try {
      const user = await getUser();
      const res = await createVipId(user, formDataToSubmit);

      if (res.status) {
        // console.log(res);
        toast.success(res.message);
        triggerFetch();
        setFormData({
          vipId: "",
          name: "",
          country: "",
          email: "",
          mobile: "",
          zone: "",
          password: "",
          reference: "SELF",
          referralCode: "",
          partnerCode: "",
        });
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="h-full">
        <form className="h-full" onSubmit={handleSubmit}>
          <div className="flex flex-col sm:flex-row justify-between items-start gap-4 w-full h-full">
            <div className="flex flex-col gap-6 w-full">
              <span className="space-y-1">
                <h1 className="text-sm">VIP ID</h1>
                <input
                  name="vipId"
                  value={formData.vipId}
                  onChange={handleChange}
                  placeholder="Enter VIP ID"
                  type="text"
                  required
                  className="border p-2 h-10 rounded-xl w-full outline-none text-sm"
                />
              </span>
              <span className="space-y-1">
                <h1 className="text-sm">Member Name</h1>
                <input
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter name"
                  type="text"
                  required
                  className="border p-2 h-10 rounded-xl w-full outline-none text-sm"
                />
              </span>
              <span className="space-y-1">
                <h1 className="text-sm">Country</h1>
                <select
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  className="w-full border rounded-xl h-10 text-sm p-2 outline-none"
                >
                  <option value="">Select your country</option>
                  <option value="India">India</option>
                  <option value="Bangladesh">Bangladesh</option>
                </select>
              </span>
              <span className="space-y-1">
                <h1 className="text-sm">Email ID</h1>
                <input
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter Email ID"
                  type="email"
                  required
                  className="border p-2 h-10 rounded-xl w-full outline-none text-sm"
                />
              </span>
              {formData.reference === "INTRODUCER" && (
                <span className="space-y-1">
                  <h1 className="text-sm">Referral Code</h1>
                  <input
                    name="referralCode"
                    value={formData.referralCode}
                    onChange={handleChange}
                    placeholder="Enter Referral Code"
                    type="text"
                    required
                    className="border p-2 h-10 rounded-xl w-full outline-none text-sm"
                  />
                </span>
              )}
              {formData.reference === "PARTNER" && (
                <span className="space-y-1">
                  <h1 className="text-sm">Partner Code</h1>
                  <input
                    name="partnerCode"
                    value={formData.partnerCode}
                    onChange={handleChange}
                    placeholder="Enter Partner Code"
                    type="text"
                    required
                    className="border p-2 h-10 rounded-xl w-full outline-none text-sm"
                  />
                </span>
              )}
            </div>
            <div className="flex flex-col gap-6 w-full">
              <span className="space-y-1">
                <h1 className="text-sm">Mobile</h1>
                <input
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  placeholder="Enter mobile number"
                  type="number"
                  required
                  className="border p-2 h-10 rounded-xl w-full outline-none text-sm"
                />
              </span>
              <span className="space-y-1">
                <h1 className="text-sm">Zone Selection</h1>
                <select
                  required
                  name="zone"
                  value={formData.zone}
                  onChange={handleChange}
                  className="w-full border rounded-xl h-10 text-sm p-2 outline-none"
                >
                  <option value="">Select Zone</option>
                  <option value="PRIVATE">Private</option>
                  <option value="PUBLIC">Public</option>
                </select>
              </span>
              <span className="space-y-1">
                <h1 className="text-sm">Password</h1>
                <input
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Enter password"
                  type="password"
                  required
                  className="border p-2 h-10 rounded-xl w-full outline-none text-sm"
                />
              </span>
              <span className="space-y-1">
                <h1 className="text-sm">Reference</h1>
                <select
                  name="reference"
                  value={formData.reference}
                  onChange={handleChange}
                  className="w-full border rounded-xl h-10 text-sm p-2 outline-none"
                >
                  <option value="SELF">Self</option>
                  <option value="INTRODUCER">Introducer</option>
                  <option value="PARTNER">Partner</option>
                </select>
              </span>
            </div>
          </div>

          <div className="h-full mt-16 xl:mt-52 flex justify-center items-center">
            <button
              type="submit"
              className="bg-custom-border w-96 p-2 rounded-xl text-white"
            >
              Add
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddVipMemberForm;
