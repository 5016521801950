import React, { memo, useState } from "react";
import FundTransferModal from "../Modals/TransferFunds/FundTransferModal";
import { User } from "lucide-react";

const SearchUser = ({ searchedUser }) => {
  const [transferModal, setTransferModal] = useState("");

  console.log(searchedUser);

  return (
    <>
      {transferModal === "on" && (
        <FundTransferModal
          searchedUser={searchedUser}
          setTransferModal={setTransferModal}
        />
      )}
      <div className="main-search-user-container">
        <div className="flex flex-col sm:flex-row justify-between items-center w-full h-full border p-2 sm:p-4 rounded-xl gap-4">
          <div className=" flex items-center gap-2 sm:gap-4 w-full">
            <div className="border-4 border-gray-300 rounded-full w-16 h-16 sm:w-20 sm:h-20 p-1 flex justify-center items-center">
              {searchedUser?.kyc?.userSelfie ? (
                <img src={searchedUser?.kyc?.userSelfie} alt="user" className=""/>
              ) : (
                <User className="w-full h-full text-neutral-300" />
              )}
            </div>
            <div className="text-sm flex flex-col gap-1">
              <span>Name: {searchedUser.name}</span>
              <span>Member ID: {searchedUser.referralCode}</span>
              <span>Balance: {searchedUser.fundBalance}</span>
            </div>
          </div>
          <div className="w-full sm:w-44">
            <button
              onClick={() => setTransferModal("on")}
              className="text-sm bg-custom-border p-2 px-4 text-white rounded-xl w-full hover:opacity-85"
            >
              Transfer
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(SearchUser);
