import React from "react";
import ModalLayout from "../Layouts/ModalLayout";
import { Download, RotateCcw, X } from "lucide-react";
import { useMutation } from "react-query";
import { getUser } from "../../services/auth";
import { downloadPdf } from "../../services/statementServices";
import { toast } from "react-toastify";

const DownloadStatementModal = ({ setNewModal, statement }) => {
  // console.log("statement", statement);

  const pdfMutation = useMutation(
    async () => {
      const user = await getUser();
      return await downloadPdf(user, statement?.data);
    },
    {
      onSuccess: (data) => {
        if (data?.status) {
          setNewModal("");
          toast.success("PDF download successfully");
        } else {
          toast.error(data?.message || "Something went wrong.");
        }
      },
      onError: (error) => {
        console.error(error);
        toast.error("An error occurred during downloading the PDF.");
      },
    }
  );

  return (
    <>
      <ModalLayout maxWidth="max-w-md">
        <div>
          <div className="flex justify-between items-center p-4 border-b">
            <h1 className="text-lg font-semibold">Download</h1>
            <button
              onClick={() => setNewModal("")}
              className="hover:bg-gray-200 hover:text-red-600 p-1 rounded-full"
            >
              <X />
            </button>
          </div>
          <div className="flex flex-col justify-center items-center p-4">
            <div className="py-12 flex flex-col justify-center items-center gap-4">
              <div>
                <img src="/images/pdf.png" alt="pdf" className="w-14 h-14" />
              </div>
              <div className="text-center flex flex-col">
                Your Statement is ready to{" "}
                <span className="text-bg-color font-semibold">Download !</span>
              </div>
            </div>
            <div className="mt-4 flex gap-4 justify-center w-full mb-4">
              <button
                // className="p-2 px-4 max-w-44 w-full rounded-xl flex justify-center items-center gap-2 bg-bg-color hover:opacity-85 "
                onClick={() => pdfMutation.mutate()}
                disabled={pdfMutation.isLoading}
                className={`max-w-44 w-full text-white ${
                  pdfMutation.isLoading
                    ? "bg-gray-400"
                    : "bg-bg-color hover:bg-opacity-85"
                } p-2 px-4 rounded-xl text-white flex justify-center items-center gap-2`}
              >
                <Download className="w-5 h-5" />
                {pdfMutation.isLoading ? "Download..." : "Download"}
              </button>
              <button
                onClick={() => setNewModal("")}
                className="max-w-44 w-full border rounded-xl p-2 px-4 hover:bg-black/5"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default DownloadStatementModal;
