import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import LoginScreen from "./screens/Auth/LoginScreen";
import DashboardScreen from "./screens/Admin/DashboardScreen";
import { AppContextWrapper } from "./context/AppContext";
import OtpScreen from "./screens/Auth/OtpScreen";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import AdminRoute from "./components/AdminRoute";
import ManageSubRetailserScreen from "./screens/ManageRetailer/ManageSubRetailserScreen";
import ProtectedRoute from "./components/ProtectedRoute";
import TransferRecordsScreen from "./screens/TransferFunds/TransferRecordsScreen";
import ManageOfferBenefits from "./screens/ManageRetailer/ManageOfferBenefits";
import BCInsuranceScreen from "./screens/Insurance/BCInsuranceScreen";
import GenerateVipIdScreen from "./screens/GenerateVipIdScreen";
import ManageAdsScreen from "./screens/ManageAdsScreen";
import VipSubscriptionScreen from "./screens/VipSubscriptionScreen";
import NutritionBazarScreen from "./screens/NutritionBazarScreen";
import DriverRegistrationScreen from "./screens/DriverRegistrationScreen";
import CryptoConsultBookingScreen from "./screens/CryptoConsultBookingScreen";
import CourierServiceScreen from "./screens/CourierServiceScreen";
import ServiceBasedScreen from "./screens/ServiceBasedScreen";
import MiningNodeBookingScreen from "./screens/MiningNodeBookingScreen";
import PrepaidCardRegisterScreen from "./screens/PrepaidCardRegisterScreen";
import CryptoSoundBoxScreen from "./screens/CryptoSoundBoxScreen";
import ResellerRegisterScreen from "./screens/ResellerRegisterScreen";
import { QueryClient, QueryClientProvider } from "react-query";
import BurnOutIdRestoreScreen from "./screens/BurnOutIdRegisterScreen";
import ManageStateRetailerScreen from "./screens/Country/ManageStateRetailerScreen";
import ManageCountryCommissionsScreen from "./screens/Country/ManageCountryCommissionsScreen";
import ManageRetailerScreen from "./screens/States/ManageRetailerScreen";
import ManageStateCommissionsScreen from "./screens/States/ManageStateCommissionsScreen";
import AcceptedStateReports from "./screens/States/AcceptedStateReports";
import PendingStateRequestScreen from "./screens/States/PendingStateRequestScreen";


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="/" index={true} element={<LoginScreen />} />
      <Route path="" element={<AdminRoute />}>
        <Route path="/dashboard" element={<DashboardScreen />} />
        <Route path="/transfer-records" element={<TransferRecordsScreen />} />
        <Route
          path="/manage-offer-benefits"
          element={<ManageOfferBenefits />}
        />
        <Route path="/bc-insurance" element={<BCInsuranceScreen />} />
        <Route path="/generate-vip-id" element={<GenerateVipIdScreen />} />
        <Route path="/manage-ads" element={<ManageAdsScreen />} />
        <Route
          path="/manage-vip-subscriptions"
          element={<VipSubscriptionScreen />}
        />
        <Route path="/product-bazar" element={<NutritionBazarScreen />} />
        <Route path="/driver-register" element={<DriverRegistrationScreen />} />
        <Route
          path="/crypto-consult-booking"
          element={<CryptoConsultBookingScreen />}
        />
        <Route path="/courier-service" element={<CourierServiceScreen />} />
        <Route path="/services-based" element={<ServiceBasedScreen />} />
        <Route
          path="/mining-node-booking"
          element={<MiningNodeBookingScreen />}
        />
        <Route
          path="/prepaid-card-register"
          element={<PrepaidCardRegisterScreen />}
        />
        <Route path="/burn-id-restore" element={<BurnOutIdRestoreScreen />} />
        <Route path="/souund-box" element={<CryptoSoundBoxScreen />} />
        <Route path="/reseller-register" element={<ResellerRegisterScreen />} />
        {/* Country */}
        <Route path="/manage-state-retailer" element={<ManageStateRetailerScreen />} />
        <Route path="/manage-country-commissions" element={<ManageCountryCommissionsScreen />} />
        {/* State */}
        <Route path="/manage-retailer" element={<ManageRetailerScreen />} />
        <Route path="/manage-state-commissions" element={<ManageStateCommissionsScreen />} />
        <Route path="/accepted-reports" element={<AcceptedStateReports />} />
        <Route path="/pending-requests" element={<PendingStateRequestScreen />} />
        {/* <Route path="" element={<ProtectedRoute />}> */}
        <Route
          path="/manage-sub-retailer"
          element={<ManageSubRetailserScreen />}
        />
        {/* </Route> */}
      </Route>
      <Route path="/otp-verification" element={<OtpScreen />} />
    </Route>
  )
);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AppContextWrapper>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
      <ToastContainer />
    </AppContextWrapper>
  </React.StrictMode>
);

reportWebVitals();
