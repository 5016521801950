import React from "react";

const AuthLayout = ({ children }) => {
  return (
    <>
      <div className="main-login-container">
        <div className="banner__logo">
          <div className="bg__image">
            <img loading="lazy" src="/images/bg.svg" alt="Background" />
          </div>
          <div className="logo z-30" >
            <img loading="lazy" src="/images/logo.png" alt="Logo" />
          </div>
        </div>
        <div className="auth-child-container">{children}</div>
      </div>
    </>
  );
};

export default AuthLayout;
