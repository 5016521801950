import React, { useState } from "react";
import MainLayout from "../components/Layouts/MainLayout";
import { CircleChevronLeft, Search, Contact } from "lucide-react";
import { Link } from "react-router-dom";
import VipIdTable from "../components/Tables/VipIdTable";
import Pagination from "../components/Pagination";
import AddVipMemberForm from "../components/Forms/AddVipMemberForm";
import { useQuery } from "react-query";
import { getUser } from "../services/auth";
import { fetchVipList } from "../services/generateVipServices";
import EditVipMemberForm from "../components/Forms/EditVipMemberForm";

const GenerateVipIdScreen = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [edit, setEdit] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const limit = 10;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to page 1 when search query changes
  };

  // console.log("selectedId", selectedId);

  const {
    data: vipUsers,
    isLoading,
    refetch: triggerFetch,
  } = useQuery({
    queryKey: ["vipUsers", currentPage, limit, searchQuery],
    queryFn: async () => {
      const user = await getUser();
      const res = await fetchVipList(user, currentPage, limit, searchQuery);
      return res;
    },
    onSuccess: (data) => {
      if (data) {
        setTotalPages(data?.data?.totalPages || 1);
      } else {
        console.warn(data?.message);
      }
    },
    refetchOnMount: true, // Always refetch on mount when the queryKey changes
    refetchOnWindowFocus: false,
    // staleTime: 5 * 60 * 1000,
  });

  // console.log("vipUsers", vipUsers);

  return (
    <>
      <MainLayout>
        <div>
          <div className="pb-4">
            <Link
              to="/dashboard"
              className="flex  items-center gap-2 bg-custom-border/20 hover:bg-custom-border/30 p-2 w-fit rounded-xl"
            >
              <CircleChevronLeft className="w-4 h-4" />
              <h1 className="font-semibold text-sm">Manage VIP ID</h1>
            </Link>
          </div>

          <div className="py-4 flex flex-col xl:flex-row gap-4 justify-center items-start w-full h-full">
            <div className="w-full xl:w-7/12">
              <div className="flex items-center gap-2 p-1 h-12 border rounded-full shadow-md">
                <span className="ml-1">
                  <Search className="w-6 h-6 text-gray-500 " />
                </span>
                <input
                  value={searchQuery}
                  onChange={handleSearchChange}
                  type="search"
                  placeholder="Search By Referral Code/Email/Mobile"
                  className="w-full h-full outline-none text-sm p-2 "
                />
                <div className="">
                  <button className="bg-bg-color w-16 h-full p-2 rounded-full text-white">
                    GO
                  </button>
                </div>
              </div>

              <div className="mt-4 w-full h-full p-4 border rounded-xl shadow-lg">
                {vipUsers?.data?.docs?.length > 0 ? (
                  <VipIdTable
                    vipUsers={vipUsers}
                    currentPage={currentPage}
                    limit={limit}
                    setEdit={setEdit}
                    selectedId={selectedId}
                    setSelectedId={setSelectedId}
                  />
                ) : (
                  <div className="w-full h-96 flex flex-col justify-center items-center gap-1">
                    <span className="flex justify-center items-center w-10 h-10 bg-gray-200 rounded-lg">
                    <Contact />
                    </span>
                    <p>There are no VIP IDs available.</p>
                  </div>
                )}
                <div className="mt-4">
                  {totalPages > 1 && vipUsers?.data?.docs?.length > 0 && (
                    <div className="mt-4">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full xl:w-5/12 h-full mt-6 xl:mt-0">
              <div className="h-10 sm:h-12">
                <button
                  onClick={() => {
                    setEdit(""); // Clear the edit state
                    setSelectedId(null); // Clear the selected ID
                  }}
                  className={` p-2 w-full sm:w-44 h-full ${
                    edit === "edit"
                      ? "border-b border-custom-border hover:bg-black/10"
                      : "bg-custom-border hover:bg-opacity-85 text-white rounded-xl "
                  }`}
                >
                  Add VIP Member
                </button>
              </div>
              <div className="mt-4 w-full h-full p-4 border rounded-xl shadow-lg">
                {edit === "edit" && selectedId ? (
                  <EditVipMemberForm
                    triggerFetch={triggerFetch}
                    selectedId={selectedId}
                    setEdit={setEdit}
                    setSelectedId={setSelectedId}
                  />
                ) : (
                  <AddVipMemberForm triggerFetch={triggerFetch} />
                )}
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default GenerateVipIdScreen;
