import React from "react";
import ModalLayout from "../Layouts/ModalLayout";
import { X } from "lucide-react";
import { useAppContext } from "../../context/AppContext";
import { getUser } from "../../services/auth";
import { deleteOfferBenefit } from "../../services/manageOfferServices";
import { toast } from "react-toastify";

const DeleteOfferBenefitModal = ({ triggerRefetch, offerId }) => {
  const { modal, setModal } = useAppContext();

  const handleDeleteBenefit = async () => {
    try {
      const user = await getUser();
      const res = await deleteOfferBenefit(user, offerId);

      if (res.status) {
        setModal("");
        toast.success(res.message);
        triggerRefetch();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <ModalLayout maxWidth="max-w-md">
        <div>
          <div className="flex justify-between items-center p-4 border-b">
            <h1 className="text-lg font-semibold">Confirmation</h1>
            <button
              onClick={() => setModal("")}
              className="hover:bg-gray-200 hover:text-red-600 p-1 rounded-full"
            >
              <X />
            </button>
          </div>
          <div className="flex flex-col justify-center items-center py-6">
            <div className="text-center py-12">
              Are you sure you want to{" "}
              <span className="text-red-500 font-semibold">Delete</span> this
              offer benefit? This action cannot be undone.
            </div>
            <div className="mt-4 flex gap-4 justify-center w-full">
              <button
                onClick={() => setModal("")}
                className="max-w-44 w-full border rounded-xl p-2 px-4"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteBenefit}
                className="max-w-44 w-full bg-red-600 hover:bg-opacity-85 p-2 px-4 rounded-xl text-white"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default DeleteOfferBenefitModal;
