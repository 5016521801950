import React, { memo } from "react";
import { formatDate } from "../utils/formatTime";
import { useAppContext } from "../context/AppContext";
import { File } from "lucide-react";

const FundTables = ({ data, currentPage, limit }) => {
  const { userData } = useAppContext();

  return (
    <>
      {data.length > 0 ? (
        <div className="mt-2">
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto overflow-y-hidden">
              <div className="align-middle inline-block min-w-full">
                <div className="overflow-hidden sm:rounded-lg border border-custom-border">
                  <table className="min-w-full">
                    <thead className="bg-sub-card border-b  border-custom-border bg-gradient-to-tr from-custom-border to-bg-color text-white">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                        >
                          Sl. No.
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                        >
                          <span className="flex text-sm">
                            Amount (
                            <img
                              src={userData?.country?.currencySymbol}
                              alt="symbol"
                              width={12}
                              height={12}
                              title={userData?.country?.currencyCode}
                            />
                            )
                          </span>
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                        >
                          Transaction ID
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                        >
                          Date Time
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                        >
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((transaction, index) => (
                        <tr
                          key={index}
                          className={
                            index % 2 === 0 ? "bg-white" : "bg-bg-color/10"
                          }
                        >
                          <td className="px-6 py-4 whitespace-nowrap text-sm flex items-center gap-1.5">
                            {index + 1 + (currentPage - 1) * limit}.
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {transaction.price}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {transaction.transactionId ?? "-"}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {formatDate(transaction.createdAt)}
                          </td>
                          <td
                            className={`px-6 py-4 whitespace-nowrap text-sm italic font-medium ${
                              transaction.status === "COMPLETED"
                                ? "status-success"
                                : transaction.status === "PENDING"
                                ? "status-pending"
                                : "status-reject"
                            }`}
                          >
                            {transaction.status}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="min-h-64 flex flex-col gap-1 justify-center items-center">
          <span className="bg-bg-color/50 p-2 rounded-lg">
            <File className="w-5 h-5 text-custom-border" />
          </span>
          <p>Not found</p>
        </div>
      )}
    </>
  );
};

export default memo(FundTables);
