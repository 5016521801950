import React, { useEffect, useState } from "react";
import ModalLayout from "../../Layouts/ModalLayout";
import { FileText, X } from "lucide-react";
import SubRetailerTxnTable from "../../Tables/SubRetailerTxnTable";
import Pagination from "../../Pagination";
import { getUser } from "../../../services/auth";
import { memberTxnsList, stateMemberTxnsList } from "../../../services/subRetailerServices";
import { useAppContext } from "../../../context/AppContext";

const TransactionsModal = ({ setModal, selectedMember }) => {
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(6);

  const { userData } = useAppContext();

  useEffect(() => {
    const handleTxnsList = async () => {
      try {
        const user = await getUser();
        let data;
        if (userData && userData.role === "RETAILER") {
          data = await memberTxnsList(
            user,
            selectedMember._id,
            currentPage,
            limit
          );
        } else {
          data = await stateMemberTxnsList(
            user,
            selectedMember._id,
            currentPage,
            limit
          );
        }
        if (data.status) {
          setTransactions(data.data.docs);
          setTotalPages(data.data.totalPages);
        }
      } catch (error) {
        console.error(error);
      }
    };

    handleTxnsList();
  }, [currentPage, limit, selectedMember]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleLimitChange = (e) => {
    setLimit(Number(e.target.value));
    setCurrentPage(1);
  };

  return (
    <>
      <ModalLayout maxWidth="max-w-2xl">
        <div className="">
          <div className="modal-header">
            <div className="modal-header-body"></div>
            <div className="modal-header-body2">Transactions</div>
            <div onClick={() => setModal("")} className="modal-header-body">
              <X style={{ cursor: "pointer" }} />
            </div>
          </div>

          <div className=" p-4 ">
            {transactions.length > 0 ? (
              <>
                <SubRetailerTxnTable transactions={transactions} />
                <div className="flex mt-6 justify-between">
                  <div>
                    <select
                      value={limit}
                      onChange={handleLimitChange}
                      className="border outline-none border-bg-color rounded-lg"
                    >
                      <option value="6">6</option>
                      <option value="12">12</option>
                    </select>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </>
            ) : (
              <div className=" min-h-56 w-full h-full flex flex-col justify-center items-center">
                <div>
                  <FileText />
                </div>
                <p>No Transactions Found.</p>
              </div>
            )}
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default TransactionsModal;
