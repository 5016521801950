import { ArrowLeft } from "lucide-react";
import React, { useState } from "react";
import AuthLayout from "../../components/Layouts/AuthLayout";
import OTPInput from "react-otp-input";
import LeaveModal from "../../components/Modals/LeaveModal";
import { useLocation, useNavigate } from "react-router-dom";
import { verifyOTP } from "../../services/userServices";
import { toast } from "react-toastify";
import { useAppContext } from "../../context/AppContext";

const OtpScreen = () => {
  const [otp, setOtp] = useState("");
  const [back, setBack] = useState(false);
  const { setRefresh } = useAppContext();

  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");

  const handleVerify = async (e) => {
    e.preventDefault();

    if (otp === "") {
      toast.error("Please enter your otp");
      return;
    }

    try {
      const data = await verifyOTP(email, otp);
      if (data.status) {
        toast.success(data.message);
        navigate("/dashboard");
        setRefresh(true);
        localStorage.setItem("user", JSON.stringify(data.data));
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error.message);
      if (error.message === "Request failed with status code 403") {
        toast.error("Wrong OTP. Please try again");
      }
    }
  };

  return (
    <>
      <AuthLayout>
        {back && <LeaveModal setBack={setBack} />}
        <div className="auth-container">
          <div className="auth-content">
            <div className="mt-4">
              <h1 className="text-2xl sm:text-4xl font-semibold">
                Retailer Web Login
              </h1>
            </div>
            <div className="auth-body">
              <form onSubmit={handleVerify}>
                <div>
                  <OTPInput
                    skipDefaultStyles
                    inputStyle="outline-none"
                    containerStyle="w-full flex justify-between items-center gap-2 sm:gap-4 w-full"
                    inputType="text"
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderInput={(props, index) => (
                      <input
                        {...props}
                        id={`otp-input-${index}`}
                        name={`otp-input-${index}`}
                        className="w-full bg-custom-bg h-12 outline-none text-center border border-black rounded-lg text-xl"
                      />
                    )}
                  />
                </div>
                <div className="flex justify-between gap-1 mt-1">
                  <p style={{ fontSize: "12px", marginTop: "2px" }}>
                    OTP will be sent to your Registered Email Address
                  </p>
                  <button
                    style={{
                      color: "rgba(21, 51, 97, 1)",
                      backgroundColor: "transparent",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    Resend
                  </button>
                </div>
                <div
                  style={{ marginTop: "6rem" }}
                  className="flex justify-between gap-2 items-center h-12"
                >
                  <button
                    onClick={() => setBack(true)}
                    className="w-16 h-full bg-gray-300 hover:bg-gray-200 rounded-xl flex justify-center items-center"
                    type="button"
                  >
                    <ArrowLeft stroke="rgba(21, 51, 97, 1)" />
                  </button>
                  <button
                    type="submit"
                    className="bg-custom-border rounded-xl w-full h-full text-white text-lg hover:opacity-85"
                  >
                    Verify
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
};

export default OtpScreen;
