import React, { useState, useEffect } from "react";
import ModalLayout from "../../Layouts/ModalLayout";
import { X } from "lucide-react";
import { useAppContext } from "../../../context/AppContext";
import { editStateMembers } from "../../../services/subRetailerServices";
import { getUser } from "../../../services/auth";
import { toast } from "react-toastify";
import { states } from "../../../array/state";

const EditStateMemberModal = ({ selectedMember }) => {
  const { setModal, userData, setRetailRefresh } = useAppContext();
  const [formData, setFormData] = useState({
    name: selectedMember?.name || "",
    email: selectedMember?.email || "",
    mobile: selectedMember?.mobile || "",
    password: "",
    address: selectedMember?.kyc?.address || "",
  });

  const [isFormChanged, setIsFormChanged] = useState(false);

  useEffect(() => {
    const initialFormData = {
      name: selectedMember?.name || "",
      email: selectedMember?.email || "",
      mobile: selectedMember?.mobile || "",
      password: "",
      address: selectedMember?.kyc?.address || "",
    };

    const hasFormChanged = Object.keys(initialFormData).some(
      (key) => initialFormData[key] !== formData[key]
    );

    setIsFormChanged(hasFormChanged);
  }, [formData, selectedMember]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      setModal("pending");
      const user = await getUser();

      const formDataToSubmit = { ...formData };

      if (!formDataToSubmit.password) {
        formDataToSubmit.password = undefined;
      }

      if (!formDataToSubmit.address) {
        formDataToSubmit.address = undefined;
      }

      const response = await editStateMembers(
        user,
        selectedMember._id,
        formDataToSubmit
      );
      if (response.status) {
        setRetailRefresh(true);
        setModal("success");
        toast.success(response.message);
      } else {
        setModal("edit-retailer");
        toast.error(response.message);
      }
    } catch (error) {
      setModal("edit-retailer");
      console.error("Error updating sub retailer:", error);
    }
  };

  return (
    <>
      <ModalLayout maxWidth="max-w-3xl">
        <div className="">
          <div className="modal-header">
            <div className="modal-header-body"></div>
            <div className="modal-header-body2">Edit State Members</div>
            <div onClick={() => setModal("")} className="modal-header-body">
              <X style={{ cursor: "pointer" }} />
            </div>
          </div>

          <div className="p-4 mt-8">
            <div className="flex flex-col sm:flex-row gap-4 text-sm">
              <div className="w-full space-y-4">
                <div className="flex">
                  <label className="flex flex-1">Retailer Name</label>
                  <input
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    type="name"
                    placeholder="Enter Full Name"
                    className="flex flex-1 border py-1 px-2 border-bg-color rounded-lg outline-none"
                  />
                </div>
                <div className="flex">
                  <label className="flex flex-1">Email ID</label>
                  <input
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    type="email"
                    placeholder="Enter Email ID"
                    className="flex flex-1 border py-1 px-2 border-bg-color rounded-lg outline-none"
                  />
                </div>
                <div className="flex">
                  <label className="flex flex-1">Mobile Number</label>
                  <input
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    type="number"
                    placeholder="Enter Mobile Number"
                    className="flex flex-1 border py-1 px-2 border-bg-color rounded-lg outline-none"
                  />
                </div>
                <div className="flex">
                  <h1 className="flex flex-1">Balance</h1>
                  <input
                    value={selectedMember?.fundBalance}
                    readOnly
                    className="flex flex-1 border py-1 px-2 border-bg-color rounded-lg outline-none"
                  />
                </div>
              </div>

              <div className="w-full space-y-4">
                <div className="flex items-center ">
                  <label className="flex flex-1">Password</label>
                  <input
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    type="password"
                    placeholder="************"
                    className="flex border py-1 px-2 border-bg-color rounded-lg outline-none w-48 sm:w-56"
                  />
                </div>
                <div className="flex">
                  <label className="flex flex-1">Country</label>
                  <select
                    style={{ cursor: "not-allowed" }}
                    disabled
                    value={selectedMember?.country.country}
                    className="border border-bg-color p-1 rounded-lg flex w-48 sm:w-56"
                  >
                    <option value="">Select Country</option>
                    <option value="USA">USA</option>
                    <option value="UK">UK</option>
                    <option value="India">India</option>
                    <option value="China">China</option>
                  </select>
                </div>
                {/* {userData?.country?.country === "India" && (
                  <div className="flex justify-between gap-2 items-center w-full">
                    <h1 className="text-sm">States</h1>
                    <select
                      name="state"
                      value={formData.state}
                      onChange={handleChange}
                      className="border p-1 rounded-lg border-custom-border/30 outline-none w-48 sm:w-56"
                    >
                      <option value="">Select State</option>
                      {Object.entries(states).map(([abbr, name]) => (
                        <option key={abbr} value={name}>
                          {name}
                        </option>
                      ))}
                    </select>
                  </div>
                )} */}
                <div className="flex gap-2 items-start">
                  <h1 className="flex-1 text-sm">Address</h1>
                  <textarea
                    type="number"
                    placeholder="Enter Address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    className="min-h-16 max-h-20 border border-custom-border/30 rounded-lg px-1.5 w-48 sm:w-56 outline-none"
                  />
                </div>
              </div>
            </div>

            <div className="add-retailer-btn-save">
              <button
                onClick={handleSubmit}
                disabled={!isFormChanged}
                style={{
                  cursor: !isFormChanged ? "not-allowed" : "pointer",
                  opacity: !isFormChanged ? 0.5 : 1,
                }}
                className="bg-bg-color w-full rounded-xl h-10 text-white"
              >
                Save Changes
              </button>
              <button
                className="rounded-xl w-full h-10 border border-bg-color "
                onClick={() => setModal("")}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default EditStateMemberModal;
