import React, { useEffect, useState } from "react";
import MainLayout from "../../components/Layouts/MainLayout";
import "./admin.css";
import FundsComponent from "../../components/DashboardComponents/FundsComponent";
import ReceiptPending from "../../components/DashboardComponents/ReceiptPending";
import AddedFundChart from "../../components/Charts/AddedFundChart";
import TransFundChart from "../../components/Charts/TransFundChart";
import { getFunds } from "../../services/dashboardServices";
import { useAppContext } from "../../context/AppContext";
import { getUser } from "../../services/auth";
import { getUserProfile } from "../../services/userServices";
import { Link } from "react-router-dom";
import { ChevronLeft, CircleChevronLeft } from "lucide-react";

const TransferRecordsScreen = () => {
  const [funds, setFunds] = useState({});
  const { fundRefresh, setFundRefresh, setCountry, setUserProfile } =
    useAppContext();

  //Fetch All Funds
  const fetchFunds = async () => {
    try {
      const user = await getUser();
      const data = await getFunds(user);
      if (data.status) {
        setFundRefresh(false);
        setFunds(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const user = await getUser();
        const data = await getUserProfile(user);

        if (data.status) {
          // console.log("User Info", data.data);
          setUserProfile(data.data);
          setCountry(data.data.country);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getUserDetails();
  }, []);

  useEffect(() => {
    fetchFunds();
  }, [fundRefresh]);

  return (
    <>
      <MainLayout>
        <div className="pb-4">
          <Link to="/dashboard" className="flex  items-center gap-2 bg-custom-border/20 hover:bg-custom-border/30 p-2 w-fit rounded-xl">
            <CircleChevronLeft className="w-4 h-4" />
            <h1 className="font-semibold text-sm">Transfer Funds</h1>
          </Link>
        </div>
        <div className="space-y-4">
          {/* UP div */}
          <div className="w-full flex flex-col xl:flex-row gap-4">
            <div className=" xl:w-7/12">
              <AddedFundChart />
            </div>
            <div className=" xl:w-5/12">
              <FundsComponent funds={funds} />
            </div>
          </div>
          {/* Down div */}
          <div className=" w-full flex flex-col xl:flex-row gap-4">
            <div className=" xl:w-7/12 ">
              <TransFundChart />
            </div>
            <div className=" xl:w-5/12 ">
              <ReceiptPending />
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default TransferRecordsScreen;
