import React, { useState } from "react";
import ModalLayout from "../Layouts/ModalLayout";
import { X } from "lucide-react";
import { useAppContext } from "../../context/AppContext";
import { reqForState } from "../../services/state/retailerServices";
import { useMutation, useQuery } from "react-query";
import { getUser } from "../../services/auth";
import { toast } from "react-toastify";
import { getStateLists } from "../../services/country/countryServices";

const RequestStateModal = () => {
  const { setModal, userData } = useAppContext();
  const [selectedState, setSelectedState] = useState(userData?.state || "");

  const mutation = useMutation({
    mutationFn: async () => {
      const user = await await getUser();
      const res = await reqForState(user, selectedState);
      return res;
    },
    onSuccess: (data) => {
      setModal("");
      toast.success(data.message);
    },
    onError: (error) => {
      toast.error(error.message);
      console.error(error);
    },
  });

  const handleRequest = () => {
    if (!selectedState) {
      toast.warn("Please select a state.");
      return;
    }
    mutation.mutate();
  };

  const { data: stateLists } = useQuery({
    queryKey: ["stateLists"],
    queryFn: async () => {
      const user = await getUser();
      const res = await getStateLists(user);
      return res;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  return (
    <ModalLayout maxWidth="max-w-md">
      <div>
        <div className="flex justify-between p-4 border-b shadow-sm">
          <h1 className="text-lg font-semibold">
            Request for a State / Division
          </h1>
          <button
            className="hover:bg-gray-100 hover:text-red-500 rounded-full p-1"
            onClick={() => setModal("")}
          >
            <X />
          </button>
        </div>

        <div className="p-4 h-full w-full">
          <div className="min-h-56 w-full h-full sm:px-8 flex flex-col justify-center items-start">
            <div className="w-full">
              <h1>
                States / Divisions <span className="text-red-500">*</span>
              </h1>
              <select
                value={selectedState}
                onChange={(e) => setSelectedState(e.target.value)}
                className="w-full border mt-1 p-2 rounded-lg text-sm outline-none"
              >
                <option value="">Please select</option>
                {stateLists?.data?.map((state, id) => (
                  <option key={id} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <button
              onClick={handleRequest}
              disabled={!selectedState || mutation.isLoading}
              className="w-full mt-4 bg-bg-color hover:bg-bg-color/80 text-white font-semibold rounded-lg py-2 px-4 disabled:bg-gray-300 disabled:cursor-not-allowed"
              type="button"
            >
              {mutation.isLoading ? "Submitting..." : "Request"}
            </button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default RequestStateModal;
