export const vipIds = [
  {
    vip_id: "BC123456789",
    member_name: "Sam",
    joining_date: "11/11/2024",
  },
  {
    vip_id: "BC123456789",
    member_name: "Sam",
    joining_date: "11/11/2024",
  },
  {
    vip_id: "BC123456789",
    member_name: "Sam",
    joining_date: "11/11/2024",
  },
  {
    vip_id: "BC123456789",
    member_name: "Sam",
    joining_date: "11/11/2024",
  },
  {
    vip_id: "BC123456789",
    member_name: "Sam",
    joining_date: "11/11/2024",
  },
  {
    vip_id: "BC123456789",
    member_name: "Sam",
    joining_date: "11/11/2024",
  },
  {
    vip_id: "BC123456789",
    member_name: "Sam",
    joining_date: "11/11/2024",
  },
  {
    vip_id: "BC123456789",
    member_name: "Sam",
    joining_date: "11/11/2024",
  },
  {
    vip_id: "BC123456789",
    member_name: "Sam",
    joining_date: "11/11/2024",
  },
];
