import { httpAxios } from "../helper/httpHelper";

export async function login(email, password) {
  try {
    const { data } = await httpAxios.post(`/retailer/auth/otp/login`, {
      email,
      password,
    });
    return data;
  } catch (error) {
    return error.response.data;
  }
}
export async function verifyOTP(email, otp) {
  try {
    const { data } = await httpAxios.post(`/retailer/auth/login`, {
      email,
      otp,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getUserProfile(user) {
  try {
    const { data } = await httpAxios.get(`/retailer/auth/profile/get`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${user}`,
      },
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function uploadMediaFile(user, file) {
  try {
    const formData = new FormData();
    formData.append("media", file);

    const { data } = await httpAxios.post(
      `/retailer/auth/media/add`,
      formData,
      {
        headers: {
          Authorization: user,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function updateProfilePicture(user, media) {
  try {
    const { data } = await httpAxios.put(
      `/retailer/auth/profile/picture/update`,
      { media },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}
