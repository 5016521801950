import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from "lucide-react";
import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handleClick = (pageOrDirection) => {
    if (typeof pageOrDirection === "number") {
      const page = pageOrDirection;
      if (page >= 1 && page <= totalPages) {
        onPageChange(page);
      }
    } else {
      let nextPage = currentPage;
      if (pageOrDirection === "first") {
        nextPage = 1;
      } else if (pageOrDirection === "prev") {
        nextPage = Math.max(currentPage - 1, 1);
      } else if (pageOrDirection === "next") {
        nextPage = Math.min(currentPage + 1, totalPages);
      } else if (pageOrDirection === "last") {
        nextPage = totalPages;
      }
      onPageChange(nextPage);
    }
  };

  const generatePageNumbers = () => {
    const range = [];
    const showEllipsisStart = currentPage > 3;
    const showEllipsisEnd = currentPage < totalPages - 2;

    if (totalPages <= 5) {
      // If total pages is 5 or less, show all pages
      for (let i = 1; i <= totalPages; i++) {
        range.push(i);
      }
    } else {
      // Always show first page
      range.push(1);

      if (showEllipsisStart) {
        range.push("...");
      }

      // Add pages around current page
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        if (i === 1 || i === totalPages) continue; // Skip if it's first or last page
        range.push(i);
      }

      if (showEllipsisEnd) {
        range.push("...");
      }

      // Always show last page
      if (totalPages > 1) {
        range.push(totalPages);
      }
    }

    // Remove duplicates
    return [...new Set(range)];
  };

  return (
    <div className="flex justify-center items-center gap-1 sm:p-2 text-xs">
      <button
        onClick={() => handleClick("first")}
        disabled={currentPage === 1}
        className={`bg-custom-border w-10 h-8 flex justify-center items-center rounded-lg text-white hover:bg-opacity-80 disabled:opacity-50 disabled:cursor-not-allowed`}
      >
        <ChevronsLeft style={{ width: "14px", height: "14px" }} />
      </button>
      <button
        onClick={() => handleClick("prev")}
        disabled={currentPage === 1}
        className={`bg-custom-border w-10 h-8 flex justify-center items-center rounded-lg text-white hover:bg-opacity-80 disabled:opacity-50 disabled:cursor-not-allowed`}
      >
        <ChevronLeft style={{ width: "14px", height: "14px" }} />
      </button>
      {generatePageNumbers().map((page, index) => (
        <button
          key={index}
          className={`w-10 h-8 flex justify-center items-center rounded-lg text-white hover:bg-opacity-80 disabled:opacity-50 disabled:cursor-not-allowed ${
            currentPage === page ? "bg-bg-color" : "bg-custom-border"
          }`}
          onClick={() => handleClick(page)}
          disabled={page === "..."}
        >
          {page}
        </button>
      ))}
      <button
        onClick={() => handleClick("next")}
        disabled={currentPage === totalPages}
        className={`bg-custom-border w-10 h-8 flex justify-center items-center rounded-lg text-white hover:bg-opacity-80 disabled:opacity-50 disabled:cursor-not-allowed`}
      >
        <ChevronRight style={{ width: "14px", height: "14px" }} />
      </button>
      <button
        onClick={() => handleClick("last")}
        disabled={currentPage === totalPages}
        className={`bg-custom-border w-10 h-8 flex justify-center items-center rounded-lg text-white hover:bg-opacity-80 disabled:opacity-50 disabled:cursor-not-allowed`}
      >
        <ChevronsRight style={{ width: "14px", height: "14px" }} />
      </button>
    </div>
  );
};

export default Pagination;