import React, { useState } from "react";
import ModalLayout from "../Layouts/ModalLayout";
import { X } from "lucide-react";
import OTPInput from "react-otp-input";
import { useMutation } from "react-query";
import { getUser } from "../../services/auth";
import { statementCheckout } from "../../services/statementServices";
import { toast } from "react-toastify";
import StatementCheckoutModal from "./StatementCheckoutModal";

const StatementOtpModal = ({
  setCheckoutData,
  selectedUser,
  setNewModal,
  otp,
  setOtp,
}) => {
  const checkoutMutation = useMutation(
    async () => {
      const user = await getUser();
      return await statementCheckout(user);
    },
    {
      onSuccess: (data) => {
        if (data.status) {
          setCheckoutData(data.data);
          setNewModal("checkout");
        } else {
          toast.error(data.message);
        }
      },
      onError: (error) => {
        console.error(error);
        toast.error("An error occurred");
      },
    }
  );

  const handleVerifyClick = () => {
    if (otp === "" || otp.length < 6) {
      toast.error("Please enter a valid OTP");
      return;
    }
    checkoutMutation.mutate();
  };

  return (
    <>
      <ModalLayout maxWidth="max-w-md">
        <div>
          <div className="flex justify-between items-center p-4 border-b">
            <h1 className="text-lg font-semibold">OTP Verification</h1>
            <button
              onClick={() => setNewModal("")}
              className="hover:bg-gray-200 hover:text-red-600 p-1 rounded-full"
            >
              <X />
            </button>
          </div>

          <div className="text-center p-4 py-8">
            <h1 className="text-xl font-semibold">Verify by OTP</h1>
            <p className="text-sm mt-1">OTP sent to {selectedUser?.email}</p>
          </div>

          <div>
            <div className="w-full p-4 px-10">
              <p className="mb-2 text-sm">OTP</p>
              <OTPInput
                skipDefaultStyles
                inputStyle="outline-none"
                containerStyle="w-full flex justify-between items-center gap-2 sm:gap-4 w-full"
                inputType="text"
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderInput={(props, index) => (
                  <input
                    {...props}
                    id={`otp-input-${index}`}
                    name={`otp-input-${index}`}
                    className="w-full bg-custom-bg h-12 outline-none text-center border border-black rounded-lg text-xl"
                  />
                )}
              />
              <div className="mt-2">
                <button className="font-semibold text-sm">Resend</button>
              </div>
            </div>
            <div className="p-4 flex justify-center items-center gap-2 my-4">
              <button
                onClick={handleVerifyClick}
                className="w-44 h-10 bg-custom-border text-white hover:opacity-85 rounded-lg"
              >
                Verify
              </button>
              <button
                onClick={() => setNewModal("")}
                className="w-44 h-10 border border-custom-border hover:bg-black/5 rounded-lg"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default StatementOtpModal;
