import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { NotebookText } from "lucide-react";
import React, { useEffect, useState } from "react";
import { addFundGraph } from "../../services/dashboardServices";
import { useAppContext } from "../../context/AppContext";
import FundHistoryModal from "../Modals/FundHistoryModal";
import { getUser } from "../../services/auth";

const AddedFundChart = () => {
  const fullYear = new Date().getFullYear();

  const [graphData, setGraphData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(fullYear);

  const { receiptRefresh, modal, setModal } = useAppContext();

  const handleGraphData = async () => {
    try {
      const user = await getUser();
      const data = await addFundGraph(user, selectedYear);
      if (data.status) {
        setGraphData(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGraphData();
  }, [receiptRefresh, selectedYear]);

  const categories = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const options = {
    chart: {
      type: "column",
      height: "340px",
      backgroundColor: "transparent",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: categories,
    },
    yAxis: {
      title: {
        text: "Recharge",
      },
      labels: {
        formatter: function () {
          const value = this.value;
          if (value >= 10000000) {
            return (value / 10000000).toFixed(1) + " Cr";
          } else if (value >= 100000) {
            return (value / 100000).toFixed(1) + " L";
          } else if (value >= 1000) {
            return (value / 1000).toFixed(1) + " K";
          } else {
            return value;
          }
        },
      },
    },
    series: [
      {
        name: "Total Fund Added Per Month",
        data: graphData,
        color: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, "rgba(59, 197, 122, 1)"],
            [1, "rgba(3, 63, 12, 1)"],
          ],
        },
      },
    ],
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
  };

  return (
    <>
      {modal === "funds-history" && <FundHistoryModal setModal={setModal} />}
      <div className="border p-4 border-custom-border rounded-xl shadow-md h-[400px]">
        <div className="w-full flex justify-between items-center">
          <h1 className="font-semibold">Added Funds History</h1>
          <span className="date-picker">
            <button title="Added Funds History" onClick={() => setModal("funds-history")} className="bg-bg-color/50 hover:bg-bg-color h-full p-1 rounded-lg border border-custom-border">
              <NotebookText className="w-5 h-5" />
            </button>
            <select onChange={(e) => setSelectedYear(e.target.value)} className="border">
              <option value="2024">2024</option>
              <option value="2023">2023</option>
              <option value="2022">2022</option>
            </select>
          </span>
        </div>
        <div className="mt-4">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      </div>
    </>
  );
};

export default AddedFundChart;
