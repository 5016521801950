import React, { useState } from "react";
import MainLayout from "../components/Layouts/MainLayout";
import Pagination from "../components/Pagination";
import {
  CircleChevronLeft,
  Contact,
  FileSpreadsheet,
  MousePointerClick,
  Search,
} from "lucide-react";
import { Link } from "react-router-dom";
import AdManageTable from "../components/Tables/AdManageTable";
import AddAdvertiseForm from "../components/Forms/AddAdvertiseForm";
import EditAdvertiseForm from "../components/Forms/EditAdvertiseForm";
import { useQuery } from "react-query";
import { getUser } from "../services/auth";
import { fetchAds } from "../services/manageAdsServices";

const ManageAdsScreen = () => {
  const [swt, setSwt] = useState("add");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedId, setSelectedId] = useState(null);

  const limit = 10;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to page 1 when search query changes
  };

  const handleAddClick = () => {
    setSelectedId(null);
    setSwt("add");
  };

  const {
    data: manageAds,
    isLoading,
    refetch: triggerFetch,
  } = useQuery({
    queryKey: ["manageAds", currentPage, limit, searchQuery],
    queryFn: async () => {
      const user = await getUser();
      const res = await fetchAds(user, currentPage, limit, searchQuery);
      return res;
    },
    onSuccess: (data) => {
      if (data?.status) {
        setTotalPages(data?.data?.totalPages || 1);
      } else {
        console.warn(data?.message);
      }
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  // console.log("manageAds", manageAds);

  return (
    <>
      <MainLayout>
        <div>
          <div className="pb-4">
            <Link
              to="/dashboard"
              className="flex  items-center gap-2 bg-custom-border/20 hover:bg-custom-border/30 p-2 w-fit rounded-xl"
            >
              <CircleChevronLeft className="w-4 h-4" />
              <h1 className="font-semibold text-sm">Manage Advertisements</h1>
            </Link>
          </div>

          <div className="py-4 flex flex-col xl:flex-row gap-8 justify-center items-start w-full h-full">
            <div className="w-full xl:w-5/12">
              <div className="flex items-center gap-2 p-1 h-12 border rounded-full shadow-md">
                <span className="ml-1">
                  <Search className="w-6 h-6 text-gray-500 " />
                </span>
                <input
                  value={searchQuery}
                  onChange={handleSearchChange}
                  type="search"
                  placeholder="Search ad title"
                  className="w-full h-full outline-none text-sm p-2 "
                />
                <div className="">
                  <button className="bg-bg-color w-16 h-full p-2 rounded-full text-white">
                    GO
                  </button>
                </div>
              </div>

              <div className="mt-4 w-full h-full p-4 border rounded-xl shadow-lg">
                {manageAds?.data?.docs?.length > 0 ? (
                  <AdManageTable
                    manageAds={manageAds}
                    currentPage={currentPage}
                    limit={limit}
                    triggerFetch={triggerFetch}
                    setEdit={setSwt}
                    selectedId={selectedId}
                    setSelectedId={setSelectedId}
                  />
                ) : (
                  <div className="w-full h-96 flex flex-col justify-center items-center gap-1">
                    <span className="flex justify-center items-center w-10 h-10 bg-gray-200 rounded-lg">
                      <FileSpreadsheet />
                    </span>
                    <p>There are no Ads Banner available.</p>
                  </div>
                )}
                <div className="mt-4">
                  {totalPages > 1 && manageAds?.data?.docs?.length > 0 && (
                    <div className="mt-4">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full xl:w-7/12 h-full">
              <div className=" sm:h-12 flex flex-col sm:flex-row items-center gap-4">
                <button
                  onClick={() => handleAddClick("add")}
                  className={` p-2 px-4 w-full sm:w-fit h-full hover:bg-opacity-85 shadow-lg rounded-md ${
                    swt === "add"
                      ? "bg-custom-border text-white "
                      : "text-black border-b-2 border-custom-border hover:bg-custom-border/10"
                  }`}
                >
                  Add Advertisement
                </button>
                <button
                  onClick={() => setSwt("edit")}
                  className={` p-2 px-4  w-full sm:w-fit h-full hover:bg-opacity-85 shadow-lg rounded-md ${
                    swt === "edit"
                      ? "bg-custom-border text-white "
                      : "text-black border-b-2 border-custom-border hover:bg-custom-border/10"
                  }`}
                >
                  Edit Advertisement
                </button>
              </div>
              <div className="mt-4 w-full h-full p-4 border rounded-xl shadow-lg">
                {swt === "add" ? (
                  <AddAdvertiseForm triggerFetch={triggerFetch} />
                ) : swt === "edit" && selectedId !== null ? (
                  <EditAdvertiseForm
                    triggerFetch={triggerFetch}
                    selectedId={selectedId}
                    setEdit={setSwt}
                    setSelectedId={setSelectedId}
                  />
                ) : (
                  <div className="h-96 w-full flex flex-col justify-center items-center gap-1">
                    <span className="flex justify-center items-center w-10 h-10 bg-gray-200 rounded-lg">
                      <MousePointerClick />
                    </span>
                    <p>Please select an advertisement to edit.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default ManageAdsScreen;
