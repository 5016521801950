// import React, { useState } from "react";
// import MainLayout from "../components/Layouts/MainLayout";
// import { Link } from "react-router-dom";
// import { ArchiveRestore, CircleChevronLeft, Search } from "lucide-react";
// import Pagination from "../components/Pagination";
// import ManageVipSubsTable from "../components/Tables/ManageVipSubsTable";
// import { useAppContext } from "../context/AppContext";
// import RenewVipSubModal from "../components/Modals/RenewVipSubModal";
// import { useQuery } from "react-query";
// import { getSubscribersList } from "../services/manageVipServices";
// import { getUser } from "../services/auth";
// import VipRenewCheckoutModal from "../components/Modals/VipRenewCheckoutModal";

// const VipSubscriptionScreen = () => {
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [selected, setSelected] = useState(null);
//   const [checkoutDetails, setCheckoutDetails] = useState({});

//   const limit = 10;

//   const { modal, setModal } = useAppContext();

//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//   };

//   const handleSearchChange = (e) => {
//     setSearchQuery(e.target.value);
//     setCurrentPage(1); // Reset to page 1 when search query changes
//   };

//   const {
//     data: subscribers,
//     isLoading,
//     refetch: triggerFetch,
//   } = useQuery({
//     queryKey: ["subscribers", currentPage, limit, searchQuery],
//     queryFn: async () => {
//       const user = await getUser();
//       return await getSubscribersList(user, currentPage, limit, searchQuery);
//     },
//     onSuccess: (data) => {
//       setTotalPages(data.data.totalPages || 1);
//     },
//     onError: (error) => {
//       console.error(error.message);
//     },
//     refetchOnMount: true,
//     refetchOnWindowFocus: false,
//   });

//   return (
//     <>
//       {modal === "renew" && (
//         <RenewVipSubModal
//           selected={selected}
//           setCheckoutDetails={setCheckoutDetails}
//         />
//       )}
//       {modal === "checkout" && (
//         <VipRenewCheckoutModal
//           selected={selected}
//           setSelected={setSelected}
//           triggerFetch={triggerFetch}
//           checkoutDetails={checkoutDetails}
//         />
//       )}
//       <MainLayout>
//         <div>
//           <div className="pb-4">
//             <Link
//               to="/dashboard"
//               className="flex  items-center gap-2 bg-custom-border/20 hover:bg-custom-border/30 p-2 w-fit rounded-xl"
//             >
//               <CircleChevronLeft className="w-4 h-4" />
//               <h1 className="font-semibold text-sm">
//                 Manage VIP Subscriptions
//               </h1>
//             </Link>
//           </div>

//           <div>
//             <div className="flex items-center max-w-2xl w-full gap-2 p-1 h-12 border rounded-full shadow-md">
//               <span className="ml-1">
//                 <Search className="w-6 h-6 text-gray-500 " />
//               </span>
//               <input
//                 value={searchQuery}
//                 onChange={handleSearchChange}
//                 type="search"
//                 placeholder="Search"
//                 className="w-full h-full outline-none text-sm p-2 "
//               />
//               <div className="">
//                 <button className="bg-bg-color w-16 h-full p-2 rounded-full text-white">
//                   GO
//                 </button>
//               </div>
//             </div>

//             <div className="mt-4 w-full h-full p-4 border rounded-xl shadow-lg">
//               {subscribers?.data?.docs?.length > 0 ? (
//                 <ManageVipSubsTable
//                   subscribers={subscribers}
//                   setModal={setModal}
//                   selected={selected}
//                   setSelected={setSelected}
//                 />
//               ) : (
//                 <div className="h-96 w-full flex flex-col justify-center items-center gap-2">
//                   <span className="flex justify-center items-center w-10 h-10 bg-gray-200 rounded-lg">
//                     <ArchiveRestore className="w-6 h-6 text-gray-500 " />
//                   </span>
//                   <p>Result not found.</p>
//                 </div>
//               )}
//               <div className="mt-4">
//                 {totalPages > 1 && (
//                   <Pagination
//                     currentPage={currentPage}
//                     totalPages={totalPages}
//                     onPageChange={handlePageChange}
//                   />
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </MainLayout>
//     </>
//   );
// };

// export default VipSubscriptionScreen;

import React from "react";
import MainLayout from "../components/Layouts/MainLayout";
import { ArrowLeft, Construction } from "lucide-react";
import { Link } from "react-router-dom";

const VipSubscriptionScreen = () => {
  return (
    <MainLayout>
      <div className="w-full min-h-screen h-full flex flex-col justify-center items-center text-center">
        <Construction className="w-16 h-16 text-gray-500 mb-4" />
        <h1 className="text-2xl font-semibold text-gray-700">Coming Soon</h1>
        <p className="mt-2 text-gray-500">
          We are working hard to bring this feature to you. Stay tuned!
        </p>

        <Link
          to="/dashboard"
          className="mt-4 px-4 py-2 bg-black text-white rounded-lg hover:bg-black/85 flex justify-center items-center gap-2 text-sm"
        >
          <ArrowLeft className="w-4 h-4" />
          Go To Dashboard
        </Link>
      </div>
    </MainLayout>
  );
};

export default VipSubscriptionScreen;
