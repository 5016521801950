import { createContext, useContext, useEffect, useState } from "react";
import { getUser } from "../services/auth";
import { getUserProfile } from "../services/userServices";

const AppContext = createContext();

export const AppContextWrapper = ({ children }) => {
  const [modal, setModal] = useState("");
  const [receiptRefresh, setReceiptRefresh] = useState(false);
  const [fundRefresh, setFundRefresh] = useState(false);
  const [retailRefresh, setRetailRefresh] = useState(false);
  const [country, setCountry] = useState({});
  const [userProfile, setUserProfile] = useState({});
  const [userData, setUserData] = useState({});
  const [refresh, setRefresh] = useState(false)

  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;

  useEffect(() => {
    const handleUserData = async () => {
      try {
        const user = await getUser();
        
        if (user === undefined || user === null) {
          setRefresh(false);
          return;
        } else {
          const res = await getUserProfile(user);
          if (res.status) {
            setRefresh(false);
            setUserData(res.data);
          } else {
            console.log("Failed to retrieve user profile");
          }
        }
      } catch (error) {
        setRefresh(false);
        console.error("Error fetching user data:", error);
      }
    };

    handleUserData();
  }, [refresh]);

  const sharedState = {
    user,
    modal,
    setModal,
    receiptRefresh,
    setReceiptRefresh,
    fundRefresh,
    setFundRefresh,
    retailRefresh,
    setRetailRefresh,
    country,
    setCountry,
    userProfile,
    setUserProfile,
    userData,
    setRefresh
  };

  return (
    <AppContext.Provider value={sharedState}>{children}</AppContext.Provider>
  );
};

export function useAppContext() {
  return useContext(AppContext);
}
