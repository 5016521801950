import React from "react";
import { useAppContext } from "../context/AppContext";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
    
    const { userProfile } = useAppContext();

  return userProfile?.role === "RETAILER" ? (
    <Outlet />
  ) : (
    <Navigate to="/dashboard" replace />
  );
};

export default ProtectedRoute;
