import { httpAxios } from "../helper/httpHelper";

export async function fetchVipList(user, page, limit, searchQuery) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/user/list?page=${page}&limit=${limit}&search=${searchQuery}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function createVipId(user, formData) {
  try {
    const { data } = await httpAxios.post(`/retailer/user/add/vip`, formData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${user}`,
      },
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function updateVipId(user, formData) {
  try {
    const { data } = await httpAxios.put(`/retailer/user/update`, formData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${user}`,
      },
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function deleteVipId(user, userId) {
  try {
    const { data } = await httpAxios.delete(`/retailer/user/delete`, {
      params: { userId },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${user}`,
      },
    });

    return data;
  } catch (error) {
    // Safely access error.response.data to prevent undefined errors
    return error.response
      ? error.response.data
      : { message: "An unknown error occurred." };
  }
}
