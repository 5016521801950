import React, { useState } from "react";
import { manageVipSubs } from "../../array/vipSubsArr";
import { useAppContext } from "../../context/AppContext";
import { Download, Pencil } from "lucide-react";
import StatementOtpModal from "../Modals/StatementOtpModal";
import { useMutation } from "react-query";
import { getUser } from "../../services/auth";
import {
  requestOtp,
  statementCheckout,
} from "../../services/statementServices";
import { toast } from "react-toastify";
import StatementCheckoutModal from "../Modals/StatementCheckoutModal";
import DownloadStatementModal from "../Modals/DownloadStatementModal";

const StatementUserTable = ({ searchedUser, currentPage, limit }) => {
  const [checkoutData, setCheckoutData] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newModal, setNewModal] = useState("");
  const [statement, setStatement] = useState(null);
  const [otp, setOtp] = useState("");

  // console.log(selectedUser);

  const otpMutation = useMutation(
    async (_user) => {
      const user = await getUser();
      return await requestOtp(user, _user.id);
    },
    {
      onSuccess: (data, _user) => {
        if (data.status) {
          setNewModal("otp");
          setSelectedUser(_user);
        } else {
          toast.error(data.message);
        }
      },
      onError: (error) => {
        console.error(error);
        toast.error("An error occurred");
      },
    }
  );

  return (
    <>
      {newModal === "otp" && (
        <StatementOtpModal
          setCheckoutData={setCheckoutData}
          selectedUser={selectedUser}
          setNewModal={setNewModal}
          otp={otp}
          setOtp={setOtp}
        />
      )}
      {newModal === "checkout" && (
        <StatementCheckoutModal
          checkoutData={checkoutData}
          selectedUser={selectedUser}
          setNewModal={setNewModal}
          otp={otp}
          setOtp={setOtp}
          setStatement={setStatement}
        />
      )}
      {newModal === "download" && (
        <DownloadStatementModal
          setNewModal={setNewModal}
          statement={statement}
        />
      )}
      {searchedUser?.data?.docs?.length > 0 && (
        <div className="overflow-x-auto overflow-y-hidden">
          <div className="align-middle inline-block min-w-full">
            <div className="overflow-hidden rounded-xl border border-custom-border">
              <table className="min-w-full">
                <thead className="bg-sub-card border-b  border-custom-border bg-gradient-to-t from-custom-border to-bg-color text-white">
                  <tr>
                    <th
                      scope="col"
                      className="p-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                    >
                      Serial No.
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                    >
                      Member Name
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                    >
                      Member ID
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                    >
                      Statement
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {searchedUser?.data?.docs?.map((user, id) => (
                    <tr
                      key={id}
                      className={id % 2 === 0 ? "bg-white" : "bg-bg-color/10"}
                    >
                      <td className="p-4 whitespace-nowrap text-sm">
                        {id + 1 + (currentPage - 1) * limit}.
                      </td>
                      <td className="p-4 whitespace-nowrap text-sm">
                        {user.name}
                      </td>
                      <td className="p-4 whitespace-nowrap text-sm">
                        {user.referralCode}
                      </td>
                      <td className="p-4 whitespace-nowrap text-sm">
                        <button
                          onClick={() => otpMutation.mutate(user)}
                          className="flex items-center gap-3 bg-custom-border hover:opacity-85 p-2 px-3 rounded-lg "
                        >
                          <Download className="w-4 h-4 text-white" />
                          <img
                            src="/images/pdf.png"
                            alt="pdf"
                            loading="lazy"
                            className="w-4 h-4"
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StatementUserTable;
